import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import 'draft-js/dist/Draft.css';
import React, { useEffect, useMemo, useState } from 'react';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Col, Label, Row } from 'reactstrap';
import FormUpload from './admin-sparsh-management-thumbnail-upload';
import { getEntity, getLyrics, uploadEntity, uploadThumbnail ,uploadNewAlbumDetails} from './admin-sparsh-reducer';







export interface IAdminSparshLyricsProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      trackId: any;
      id?: string;
      association: string;
      url: string;
      liveVideoId: string;
    }> {}

export const AdminSparshAddNewAlbum = (props: IAdminSparshLyricsProps) => {



  const { associationEntity, loading, updating, entity } = props;
  const [file, setFile] = useState<any>(null);
  const [isPreview, setPreview] = useState(props?.entity?.content?.[0]?.thumbnailUrl);
  const [fileShow, setFileShow] = useState(null);
  const [isImageError, setImageError] = useState<boolean>(false);



  const handleGoBack = () => {
    props.history.goBack();
  };

  const isImageSizeAppropriate = img => {

    return new Promise((resolve, reject) => {
      const image = new Image();
      image.src = URL.createObjectURL(img);

      image.onload = () => {
        if (image.width === image.height) {
          resolve(true);
        } else {
          resolve(false);
        }
      };

      image.onerror = () => {
        reject('Failed to load image.');
      };
    });
  };

  const convertBase64 = files => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);

      fileReader.onload = () => {
        resolve(fileReader.result);
        setFileShow(fileReader.result);
        setImageError(false);
      };

      fileReader.onerror = error => {
        reject(error);
        setImageError(true);
      };
    });
  };

  const uploadSingleFile = async e => {
    if (e?.target?.files[0]) {
      const file = e.target.files[0];

      try {
        const isSizeAndDimensionsAppropriate = await isImageSizeAppropriate(file);

        if (isSizeAndDimensionsAppropriate) {
          setPreview(true);
          setFile(file);
          convertBase64(file);
        } else {
          setImageError(true);
          setFile(null);
          toast.error('Please select an image file with equal dimensions (eg. 600x600).');
        }
      } catch (error) {
        console.error(error);
      }
    }
  };


  const [albumDetails, setAlbumDetails] = useState(null);

  useEffect(() => {
    const album = props?.entity?.content?.[0]?.audioAlbum;
    setAlbumDetails(album);
  }, [props?.entity]);

  const config = useMemo(
    () => ({
      readonly: false,
      height: 500,
      width: 500,
    }),
    []
  );


  const handleFormSubmit = async (event, values) => {
    if (!file){
      toast.error('Please add an image');
      return
    } 
  
    let result = await props?.uploadNewAlbumDetails(values?.title, file);
  
    if (result) {
      handleGoBack();
    }
  };
  


  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={handleGoBack}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Add Album Details</h2>
            </div>
          </div>
        </Col>

        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (


            <AvForm
              onValidSubmit={handleFormSubmit} 
            >
              <div className="row">
                {/* Left Side */}
                <div className="col-md-6">
                  <AvGroup className="input-box-width">
                    <Label id="title" for="title" className="label-font">
                      Album Title*
                    </Label>
                    <AvField
                      id="title"
                      type="text"
                      name="title"
                      className="input-font"
                      validate={{
                        required: { value: true, errorMessage: 'Album Title is required' },
                      }}
                    />
                  </AvGroup>
                </div>
              </div>

              <div className="row">
                <div className="col-md-12">
                  <div className="image-container">
                    <FormUpload
                      label={'Upload Thumbnail here'}
                      errorMsg={isImageError}
                      inlineLabel={false}
                      value={null}
                      style={{ height: 150 }}
                      uploadSingleFile={uploadSingleFile}
                    />
                    {fileShow ? (
                      <FormUpload
                        isPreview={isPreview}
                        inlineLabel={false}
                        value={fileShow}
                        style={{ height: 150 }}
                        uploadSingleFile={uploadSingleFile}
                      />
                    ) : (
                      <>
                        {entity && props?.entity?.content?.[0]?.thumbnailUrl && (
                          <img src={props?.entity?.content?.[0]?.thumbnailUrl} style={{ height: 150, marginTop: 20 }} alt="Thumbnail" />
                        )}
                      </>
                    )}
                  </div>

                  {/* Submit button */}
                  <button
                    disabled={updating}
                    id="upload-thumbnail"
                    type="submit" 
                    className="common-btn"
                    style={{
                      width: 200,
                      marginTop: 20,
                    }}
                  >
                    <span className="header-title">Save</span>
                  </button>
                </div>
              </div>
            </AvForm>

          
          )}
        </Col>
      </Row>


    </div>
  );
};

const mapStateToProps = ({ association, sparsh, ...storeState }: IRootState) => ({
  entity: association?.entity || {}, 
  loading: association?.loading || false, 
  updating: association?.updating || false, 
  updateSuccess: association?.updateSuccess || false, 
  albumDetails: sparsh?.albumDetails || null, 
  totalItems: sparsh?.entity.totalElements,

});


const mapDispatchToProps = {
  getEntity,
  getLyrics,
  uploadEntity,
  uploadThumbnail,
  uploadNewAlbumDetails,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshAddNewAlbum);
