
import { ICrudGetAction, TextFormat } from 'react-jhipster';
import { getEntity, getAttachments,reset, getPlaylists, removePlaylist, removeAssociation } from './video-meta-data.reducer';
import { IVideoMetaData } from 'app/shared/model/video-meta-data.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT, SERVER_API_URL } from 'app/config/constants';
import './video-meta-data.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tabs, Tab, Box } from '@material-ui/core';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link, RouteComponentProps, useLocation } from 'react-router-dom';
import { getSortState, JhiPagination, JhiItemCount } from 'react-jhipster';
import { Modal, ModalHeader, ModalBody, ModalFooter, Button, NavLink, Row, Table, Col } from 'reactstrap';
import { ITEMS_PER_PAGE } from 'app/shared/util/pagination.constants';
import { overridePaginationStateWithQueryParams } from 'app/shared/util/entity-utils';

import Satshrut from '../../../content/images/satshrut3.png';
import { makeStyles } from '@material-ui/core/styles';
export interface IAdminPlaylistAssociationsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string, id: string }> { }
const useStyles = makeStyles((theme) => ({
  tabIndicator: {
    display: 'none',
  },
  tab: {
    textTransform: 'none',
    fontWeight: 'bold',
    fontSize: '16px',
    backgroundColor: '#eee',
    padding: '10px 15px',
    marginRight: '4px',
    borderRadius: '4px 4px 0 0',
    borderBottom: 'none',
    zIndex: 1,
    '&:hover': {
      backgroundColor: '#ddd',
    },
  },
  activeTab: {
    backgroundColor: '#ddd',
    borderTop: `3px solid ${theme.palette.primary.main}`,
  },
  contentContainer: {
    border: '1px solid #ccc',
    borderTop: 'none',
    padding: '20px',
    borderTopLeftRadius: '0',
    borderTopRightRadius: '0',
  },
}));
export interface IVideoMetaDataDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ pSearch:string,id: string,pPage:string,pSort:string,pOrder:string }> { }
enum Tablist {
  playlist = 'playlist',
  audio = 'audio',
  ebook = 'ebook',
  pad = 'pad',
  patra = 'patra',
  transcript = 'transcript'
}
export const VideoMetaDataDetail = (props: IVideoMetaDataDetailProps) => {
  useEffect(() => {
    props.getEntity(props.match.params.id);
  }, []);
  const { videoMetaDataEntity, entities, history, loading, match,updating, updatingSuccess, totalItems } = props;
  const [paginationState, setPaginationState] = useState(
    overridePaginationStateWithQueryParams(getSortState(props.location, ITEMS_PER_PAGE), props.location.search)
  );
  const location = useLocation()
  const classes = useStyles();
  const [activeTab, setActiveTab] = useState(location?.state?.showTab ? location.state.showTab : Tablist.playlist);
  const [videoPlaylistDel, setVideoPlaylistDel] = useState(false)
  const [playlistId, setPlaylistId] = useState(null)
  const [searchText, setSearchText] = useState(null)
  const [isNewSearch, setIsNewSearch] = useState(false)

  const [playListAssociationDel, setVideoAssociationDel] = useState(false)
  const [associationId, setAssociationId] = useState(null)
  const [imageKey, setImageKey] = useState(true);
  const [show,setShow]=useState(false)
  const [currentTimeout, setCurrentTimeout] = useState(null)
  const sort = p => () => {
    setPaginationState({
      ...paginationState,
      order: paginationState.order === 'asc' ? 'desc' : 'asc',
      sort: p,
    });
  };
  // const isPaginationCorrect = (sortColumn): Boolean => {
  //   if ((activeTab !== 'playlist') && (sortColumn === 'title' || sortColumn === 'publish_date' || sortColumn === 'keywords' || sortColumn === 'contentId')) {
  //     return true
  //   }
  //   if ((activeTab === 'playlist') && (sortColumn === 'id' || sortColumn === 'playlistName' || sortColumn === 'status')) {
  //     return true
  //   }
  //   return false
  // }
  const isPaginationCorrect = (sortColumn): string => {
    switch (activeTab) {
      case 'playlist':
        if ( sortColumn === 'playlistName' || sortColumn === 'status') {
          return sortColumn;
        } else {
          return 'playlistName';
        }

      case 'audio':
        if (sortColumn === 'title' || sortColumn === 'publish_date' || sortColumn === 'keywords' || sortColumn === 'contentId') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'ebook':
        if (sortColumn === 'title' || sortColumn === 'publish_date' || sortColumn === 'keywords' || sortColumn === 'contentId') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'pad':
        if (sortColumn === 'title' || sortColumn === 'publish_date' || sortColumn === 'keywords' || sortColumn === 'contentId') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'patra':
        if (sortColumn === 'title' || sortColumn === 'publish_date' || sortColumn === 'keywords' || sortColumn === 'contentId') {
          return sortColumn;
        } else {
          return 'title';
        }

      case 'transcript':
        if (sortColumn === 'title' || sortColumn === 'satsang_date' || sortColumn === 'keywords' || sortColumn === 'contentId') {
          return sortColumn;
        } else {
          return 'title';
        }
      // default:
      //   return 'id';
    }
  };
  const getAllEntities = () => {
    if (activeTab === "playlist") {
      const playlistData = {
        searchValue: searchText ? searchText : null,
        pageNo: paginationState.activePage - 1,
        pageSize: paginationState.itemsPerPage,
        contentId: props.match.params.id,     // video id
        contentType: "VIDEO",
        sortColumn: isPaginationCorrect(paginationState.sort) ,
        sortDirection: paginationState.order

      }
      props.getPlaylists(
        playlistData
      );
    } else {
      const associationData = {
        searchValue: searchText ? searchText : null,
        sortColumn: isPaginationCorrect(paginationState.sort),
        sortDirection: paginationState.order,
        pageNo: paginationState.activePage - 1,
        pageSize: paginationState.itemsPerPage,
        contentType: activeTab.toUpperCase(),
        referencesContentId: props.match.params.id,
      }
      props.getAttachments(associationData)



    }



  };

  const sortEntities = () => {
    getAllEntities();
 
    const endURL = `?page=${paginationState.activePage}&sort=${isPaginationCorrect(paginationState.sort)},${paginationState.order}`;
    if (props.location.search !== endURL) {
      props.history.push(`${props.location.pathname}${endURL}`);
    }
    isNewSearch&&setIsNewSearch(false)

  };

  useEffect(() => {
   
    videoMetaDataEntity && sortEntities();
    currentTimeout && clearTimeout(currentTimeout)
    setCurrentTimeout(null)
    const timeout = setTimeout(() => {
      setShow(true)
    }, 150)
    setCurrentTimeout(timeout)
    const endURL = `?page=${paginationState.activePage}&sort=${ isPaginationCorrect(paginationState.sort)},${paginationState.order}`;

    props.history.push(`${props.location.pathname}${endURL}`, { showTab: activeTab })
  }, [paginationState.activePage, paginationState.order, paginationState.sort, activeTab]);
  useEffect(() => {
    isNewSearch && paginationState.activePage === 1 && sortEntities()
  }, [isNewSearch])
  

  const handlePagination = currentPage =>
    setPaginationState({
      ...paginationState,
      activePage: currentPage,
    });
  const isPreview = false;

  const handleCloseVidDelModel = () => {
    setVideoPlaylistDel(false)
  };
  const handleCloseAssocnDelModel = () => {
    setVideoAssociationDel(false)
  };


  const confirmDeletePlaylist = async() => {
   const result=await props.removePlaylist(props?.match?.params?.id, playlistId,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      `${isPaginationCorrect(paginationState.sort)}`, `${paginationState.order}`, searchText ? searchText : null
    );
   
    if(result){
      setVideoPlaylistDel(false)
     }
  };
  const confirmDeleteAssociation = async() => {
   const result= await props.removeAssociation(
      associationId,
      activeTab,
      activeTab.toUpperCase(),
      searchText ? searchText : null,
      props.match.params.id,
      paginationState.activePage - 1,
      paginationState.itemsPerPage,
      isPaginationCorrect(paginationState.sort),
      paginationState.order);
   if(result){
    setVideoAssociationDel(false)
   }
  };
  const videoPlaylistDelete = (id) => {
    setVideoPlaylistDel(true)
    setPlaylistId(id)
  };
  const videoAssociationDelete = (id) => {
    setVideoAssociationDel(true)
    setAssociationId(id)
  };


  const getImageUrl = (item) => {
    if (item.thumbnail) {
      const cacheBuster = imageKey ? Date.now() : '';
      return `${SERVER_API_URL}/api/system/playlist/thumbnail/${item.id}?t=${cacheBuster}`;
    }
    return Satshrut;
  };


  const handleTabClick = (event: React.ChangeEvent<{}>, newValue: Tablist) => {
    if(activeTab!=newValue){
      setShow(false)
      setActiveTab(newValue);
    }

  };
  const handleSearchButton = () => {
    setPaginationState({
      ...paginationState,
      activePage: 1,
    });
    setIsNewSearch(true)
  }

  const handleSearch = e => {
    if (e.target.value) {
      setSearchText(e.target.value)
    }
    else {
      setSearchText(e.target.value)
      !isNewSearch && handleSearchButton()
    }
  }



const handleGoBack=()=>{
  props.reset()
  props.history.push(`/video-meta-data?searchValue=${props.match.params.pSearch}&page=${props.match.params.pPage}&sort=${props.match.params.pSort},${props.match.params.pOrder}`)
}
const handleGoForward=()=>{
  props.reset()
  props.history.push(`/video-meta-data/${videoMetaDataEntity.id}/edit?searchValue=${props.match.params.pSearch}&page=${props.match.params.pPage}&sort=${props.match.params.pSort},${props.match.params.pOrder}`)
}
  return (
    <div className='row'>
      <Col md="12">
        {/* <h2>
          VideoMetaData [<b>{videoMetaDataEntity.id}</b>]
        </h2> */}
        <div className='detail-page-heading'>
          <div className='detail-back-btn'>
            <NavLink
            onClick={handleGoBack}
            //  tag={Link} to={}
             >
              <FontAwesomeIcon icon="arrow-left" style={{ 'height': 30, 'width': 40 }} />
            </NavLink>
            <h2 style={{ color: 'rgb(114 108 108)' }}>Details</h2>
          </div>
          <div className='common-btn' style={{ 'width': '112px', paddingLeft: 18 }}>
            <NavLink
            //  tag={Link}
            //   to={}
            onClick={handleGoForward}
              >
              <span className='header-title'>Edit</span>
            </NavLink>
          </div>
        </div>

        <div className="row main-container">
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className='detail-lable-txt'>
                Video Url
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity.videoUrl}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" videoId" className='detail-lable-txt'>
                VideoId
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.videoId}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" channelName" className='detail-lable-txt'>
                Channel Name
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.channelName}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoDescription" className='detail-lable-txt'>
                Video Description
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.videoDescription}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" videoTitle" className='detail-lable-txt'>
                Video Title
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.videoTitle}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="category" className='detail-lable-txt'>
                Category
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.category}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="audience" className='detail-lable-txt'>
                Audience
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.audience}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="language" className='detail-lable-txt'>
                Language
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.language ? videoMetaDataEntity.language.charAt(0).toUpperCase() + videoMetaDataEntity.language.slice(1) : '--'}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="location" className='detail-lable-txt'>
                Location
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.location}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videotype" className='detail-lable-txt'>
                Video Type
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.videoType}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="subscriptionPlan" className='detail-lable-txt'>
                Subscription Plan
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity.subscriptionPlan ? videoMetaDataEntity.subscriptionPlan.subscriptionName : ''}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="speaker" className='detail-lable-txt'>
                Speaker
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.speaker}
              </p>
            </div>
          </div>


          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="keywords" className='detail-lable-txt'>
                Keywords
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.keywords ? videoMetaDataEntity?.keywords : '--'}
              </p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="lastUpdatedDate" className='detail-lable-txt'>
                Last Updated Date
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.updatedDate}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="publishDateLabel" className='detail-lable-txt'>
                Publish Date
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.publishDate ? videoMetaDataEntity?.publishDate : '--'}
              </p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="statuses" className='detail-lable-txt'>
                Status
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity.statuses ? videoMetaDataEntity.statuses.statusValue : '--'}
              </p>
            </div>
          </div>

          
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="statuses" className='detail-lable-txt'>
                Crowd Sourcing
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity?.crowed ? "On" : 'Off'}
              </p>
            </div>
          </div>


          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" videoId" className='detail-lable-txt'>
                Video Record Date
              </p>
              <p className='detail-txt video-record'>
                {videoMetaDataEntity?.videoRecordDate ? (
                  <TextFormat value={videoMetaDataEntity?.videoRecordDate} type="date" format={APP_LOCAL_DATE_FORMAT} />
                ) : '--'}
              </p>
            </div>
          </div>


          <div className="col-md-12 bottom-border">
            <div className="d-flex">
              <p id="statuses" className='detail-lable-txt'>
                Audio URL
              </p>
              <p className='detail-txt'>
                {videoMetaDataEntity.audioUrl ? videoMetaDataEntity.audioUrl : '--'}
              </p>
            </div>
          </div>


        </div>
      </Col >
      {videoMetaDataEntity && <Col md="12">
        <div className="main-container">
          <div className="page-title-btn-container">
            <Row className="justify-content-center">
              <Col md="12">
                <div className="detail-page-heading">
                  <div className="detail-back-btn">


                    <h2 style={{ color: 'rgb(114 108 108)' }}>Associations</h2>
                  </div>
                </div>
              </Col>
            </Row>
            <div className="float-left">
              <div className="search-container">
                <input
                  type="search"
                  id="search-input"
                  className="form-control search-input"
                  onChange={e => handleSearch(e)}
                  placeholder="Search by title"
                  aria-label="Search"
                />
                <button id="search-button" onClick={() => handleSearchButton()}>Search</button>
              </div>
            </div>


          </div>
          <Tabs onChange={handleTabClick} value={activeTab} classes={{ indicator: classes.tabIndicator }}>
            <Tab label="Playlist" value={Tablist.playlist} className={`${classes.tab} ${activeTab === Tablist.playlist ? classes.activeTab : ''}`} />
            <Tab label="Audio" value={Tablist.audio} className={`${classes.tab} ${activeTab === Tablist.audio ? classes.activeTab : ''}`} />
            <Tab label="E-book" value={Tablist.ebook} className={`${classes.tab} ${activeTab === Tablist.ebook ? classes.activeTab : ''}`} />
            <Tab label="Pad" value={Tablist.pad} className={`${classes.tab} ${activeTab === Tablist.pad ? classes.activeTab : ''}`} />
            <Tab label="Patra" value={Tablist.patra} className={`${classes.tab} ${activeTab === Tablist.patra ? classes.activeTab : ''}`} />

            <Tab label="Transcript" value={Tablist.transcript} className={`${classes.tab} ${activeTab === Tablist.transcript ? classes.activeTab : ''}`} />

          </Tabs>
          <div className="table-responsive">
            <div className="table-responsive">
              {(activeTab === 'playlist'&&entities &&show) && <> { entities.length > 0 ? (
                <Table responsive style={{ marginTop: 10 }} id='userTableId'>
                  <thead>
                    <tr>
           
                      <th className="hand" >
                        Thumbnail

                      </th>
                      <th className="hand"
                        onClick={sort('playlistName')}
                      >
                        Playlist Title
                        <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>
                      </th>
                      <th className="hand" >
                        Video Count

                      </th>
                      <th className="hand" >
                        Audio

                      </th>

                      <th className="hand">
                        E-book Count

                      </th>
                      <th className="hand">
                        Pad Count

                      </th>
                      <th className="hand">
                        Patra Count

                      </th>
                      <th className="hand">
                        Transcript Count

                      </th>






                      <th className="hand"
                        onClick={sort('status')}
                      >
                        Status
                        <span style={{ float: 'right' }}> <FontAwesomeIcon icon="sort" /></span>

                      </th>
                      {/* <th /> */}
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {entities?.map((playListMetaData, i) => (
                      <tr key={`entity-${i}`}>
                      
                        <td>
                          {playListMetaData && (
                            <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: '100%' }}>
                              {playListMetaData.thumbnail ? <img
                                src={getImageUrl(playListMetaData)}
                                style={{ maxWidth: 70, maxHeight: 50 }}
                                key={playListMetaData.id}
                              ></img>
                                : <img
                                  src={Satshrut}
                                  style={{ maxWidth: 70, maxHeight: 50 }}
                                ></img>
                              }
                            </div>
                          )}
                        </td>

                        <td>
                          {playListMetaData?.playlistName ? playListMetaData.playlistName : '--'}
                        </td>
                        <td>
                          {playListMetaData?.noOfVideos ? playListMetaData.noOfVideos : '--'}
                        </td>
                        <td>
                          {playListMetaData?.noOfaudios ? playListMetaData.noOfaudios : '--'}
                        </td>
                        <td>
                          {playListMetaData?.noOfEbooks ? playListMetaData.noOfEbooks : '--'}
                        </td>
                        <td>
                          {playListMetaData?.noOfPads ? playListMetaData.noOfPads : '--'}
                        </td>
                        <td>
                          {playListMetaData?.noOfPatras ? playListMetaData.noOfPatras : '--'}
                        </td>
                        <td>
                          {playListMetaData?.noOfTranscript ? playListMetaData.noOfTranscript : '--'}
                        </td>



                        <td>{playListMetaData?.status ? playListMetaData?.status : '--'}</td>
                        {/* <td /> */}
                        <td>
                          <div className="btn-group flex-btn-group-container">

                            <button
                              onClick={() => videoPlaylistDelete(playListMetaData.id)}
                            >
                              <FontAwesomeIcon
                                icon="trash"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginBottom: 2,
                                  color: '#db261d',
                                }}
                                data-toggle="tooltip"
                                title="Delete"
                              />
                            </button>


                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                !loading && <div className="alert alert-warning">No Playlist Data found</div>
              )}</>}
              {(activeTab === 'audio'&&entities &&show) && (
                <>
                  { entities.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                         
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                       

                          <th className="hand" onClick={sort('title')}>
                            Audio Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" >
                            Audio Url
                        
                          </th>
                          <th className="hand" onClick={sort('keywords')}>
                            Audio Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" onClick={sort('publish_date')}>
                            Publish Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>


                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entities?.map((audioMetaData, i) => (
                          <tr key={`entity-${i}`}>
                           
                            <td>
                              {audioMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {audioMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={audioMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={audioMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                          

                            <td>{audioMetaData?.title ? audioMetaData.title : '--'}</td>
                            <td>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Apple Url : {audioMetaData?.contentUrlApple ? audioMetaData.contentUrlApple : '--'}</p>
                            <p>Spotify Url : {audioMetaData?.contentUrlSpotify ? audioMetaData.contentUrlSpotify : '--'}</p>
                          </div>
                        </td>
                            <td>{audioMetaData?.keywords ? audioMetaData.keywords : '--'}</td>

                            <td>{audioMetaData?.publishDate ? audioMetaData.publishDate : '--'}</td>

       
                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink
                                  onClick={() => videoAssociationDelete(audioMetaData.contentId)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Audio Data found</div>
                  )}
                </>
              )}
              {(activeTab === 'ebook'&&entities &&show) && (
                <>
                  {entities.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                       
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                         
                          <th className="hand" onClick={sort('title')}>
                            E-book Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" >
                            E-book Url
                           
                          </th>
                          <th className="hand" onClick={sort('keywords')}>
                            E-book Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" onClick={sort('publish_date')}>
                            Publish Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                     

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entities?.map((ebookMetaData, i) => (
                          <tr key={`entity-${i}`}>
                          
                            <td>
                              {ebookMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {ebookMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={ebookMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={ebookMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                            

                            <td>{ebookMetaData?.title ? ebookMetaData.title : '--'}</td>
                            <td>{ebookMetaData?.contentUrl ? ebookMetaData.contentUrl : '--'}</td>
                            <td>{ebookMetaData?.keywords ? ebookMetaData.keywords : '--'}</td>

                            <td>{ebookMetaData?.publishDate ? ebookMetaData.publishDate : '--'}</td>

                         
                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink

                                  onClick={() => videoAssociationDelete(ebookMetaData.contentId)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No E-book Data found</div>
                  )}
                </>
              )}
              {(activeTab === 'pad' &&entities &&show) &&(
                <>
                  { entities.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                         
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                          

                          <th className="hand" onClick={sort('title')}>
                            Pad Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" >
                            Pad Url
                           
                          </th>
                          <th className="hand" onClick={sort('keywords')}>
                            Pad Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" onClick={sort('publish_date')}>
                            Publish Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                        

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entities?.map((padMetaData, i) => (
                          <tr key={`entity-${i}`}>
               
                            <td>
                              {padMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {padMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={padMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={padMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                            

                            <td>{padMetaData?.title ? padMetaData.title : '--'}</td>
                            <td>
                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <p>Apple Url : {padMetaData?.contentUrlApple ? padMetaData.contentUrlApple : '--'}</p>
                            <p>Spotify Url : {padMetaData?.contentUrlSpotify ? padMetaData.contentUrlSpotify : '--'}</p>
                          </div>
                        </td>
                            <td>{padMetaData?.keywords ? padMetaData.keywords : '--'}</td>

                            <td>{padMetaData?.publishDate ? padMetaData.publishDate : '--'}</td>

                          
                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink

                                  onClick={() => videoAssociationDelete(padMetaData.contentId)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Pad Data found</div>
                  )}
                </>
              )}
              {(activeTab === 'patra'&&entities &&show) && (
                <>
                  { entities.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                      
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                    

                          <th className="hand" onClick={sort('title')}>
                            Patra Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" >
                            Patra Url
                          
                          </th>
                          <th className="hand" onClick={sort('keywords')}>
                            Patra Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" onClick={sort('publish_date')}>
                            Publish Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                        

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entities?.map((patraMetaData, i) => (
                          <tr key={`entity-${i}`}>
                       
                            <td>
                              {patraMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {patraMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={patraMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={patraMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                           

                            <td>{patraMetaData?.title ? patraMetaData.title : '--'}</td>
                            <td>{patraMetaData?.contentUrl ? patraMetaData.contentUrl : '--'}</td>
                            <td>{patraMetaData?.keywords ? patraMetaData.keywords : '--'}</td>

                            <td>{patraMetaData?.publishDate ? patraMetaData.publishDate : '--'}</td>

                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink

                                  onClick={() => videoAssociationDelete(patraMetaData.contentId)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No patra Data found</div>
                  )}
                </>
              )}
              {(activeTab === 'transcript'&&entities &&show) && (
                <>
                  {entities.length > 0 ? (
                    <Table responsive style={{ marginTop: 10 }} id="userTableId">
                      <thead>
                        <tr>
                   
                          <th className="hand">
                            Thumbnail
                            <span style={{ float: 'right' }}> </span>
                          </th>
                         

                          <th className="hand" onClick={sort('title')}>
                            Transcript Title
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" >
                            Transcript Url
                       
                          </th>
                          <th className="hand" onClick={sort('keywords')}>
                            Transcript Keywords
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                          <th className="hand" onClick={sort('satsang_date')}>
                            Satsang Date
                            <span style={{ float: 'right' }}>
                              {' '}
                              <FontAwesomeIcon icon="sort" />
                            </span>
                          </th>

                        

                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {entities?.map((transcriptMetaData, i) => (
                          <tr key={`entity-${i}`}>
                       
                            <td>
                              {transcriptMetaData && (
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
                                  {transcriptMetaData.thumbnailImageUrl ? (
                                    <img
                                      src={transcriptMetaData.thumbnailImageUrl}
                                      style={{ maxWidth: 70, maxHeight: 50 }}
                                      key={transcriptMetaData.id}
                                    ></img>
                                  ) : (
                                    <img src={Satshrut} style={{ maxWidth: 70, maxHeight: 50 }}></img>
                                  )}
                                </div>
                              )}
                            </td>
                        

                            <td>{transcriptMetaData?.title ? transcriptMetaData.title : '--'}</td>
                            <td>{transcriptMetaData?.contentUrl ? transcriptMetaData.contentUrl : '--'}</td>
                            <td>{transcriptMetaData?.keywords ? transcriptMetaData.keywords : '--'}</td>

                            <td>{transcriptMetaData?.publishDate ? transcriptMetaData.publishDate : '--'}</td>

                        
                            <td>
                              <div className="btn-group flex-btn-group-container">

                                <NavLink
                                  onClick={() => videoAssociationDelete(transcriptMetaData.contentId)}
                                >
                                  <FontAwesomeIcon
                                    icon="trash"
                                    style={{
                                      height: ' 23px',
                                      width: '18px',
                                      marginBottom: 2,
                                      color: '#db261d',
                                    }}
                                    data-toggle="tooltip"
                                    title="Delete"
                                  />
                                </NavLink>
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  ) : (
                    !loading && <div className="alert alert-warning">No Transcript Data found</div>
                  )}
                </>
              )}
            </div>{' '}
            {props.totalItems ? (
              <div className={(show&&entities && entities.length > 0) ? '' : 'd-none'}>
                <Row className="justify-content-center">
                  <JhiItemCount page={paginationState.activePage} total={totalItems} itemsPerPage={paginationState.itemsPerPage} />
                </Row>
                <Row className="justify-content-center">
                  <JhiPagination
                    activePage={paginationState.activePage}
                    onSelect={handlePagination}
                    maxButtons={5}
                    itemsPerPage={paginationState.itemsPerPage}
                    totalItems={props.totalItems}
                  />
                </Row>
              </div>
            ) : (
              ''
            )}
          </div>
          <Modal isOpen={videoPlaylistDel} toggle={handleCloseVidDelModel}>
            <ModalHeader toggle={handleCloseVidDelModel}>Confirm delete operation</ModalHeader>
            <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCloseVidDelModel}>
                <FontAwesomeIcon icon="ban" />
                &nbsp; Cancel
              </Button>
              <Button disabled={updating} id="jhi-confirm-delete-videoMetaData" color="danger" onClick={confirmDeletePlaylist}>
                <FontAwesomeIcon icon="trash" />
                &nbsp; Delete
              </Button>
            </ModalFooter>
          </Modal>
          <Modal isOpen={playListAssociationDel} toggle={handleCloseAssocnDelModel}>
            <ModalHeader toggle={handleCloseAssocnDelModel}>Confirm delete operation</ModalHeader>
            <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this {activeTab}?</ModalBody>
            <ModalFooter>
              <Button color="secondary" onClick={handleCloseAssocnDelModel}>
                <FontAwesomeIcon icon="ban" />
                &nbsp; Cancel
              </Button>
              <Button disabled={updating} id="jhi-confirm-delete-videoMetaData" color="danger" onClick={confirmDeleteAssociation}>
                <FontAwesomeIcon icon="trash" />
                &nbsp; Delete
              </Button>
            </ModalFooter>
          </Modal>


        </div>
      </Col>}
    </div >
  );
};

const mapStateToProps = ({ videoMetaData }: IRootState) => ({
  videoMetaDataEntity: videoMetaData.entity,
  entities: videoMetaData.entities,
  loading: videoMetaData.loading,
  updatingSuccess: videoMetaData.updateSuccess,
  totalItems: videoMetaData.totalItems,
  updating:videoMetaData.updating
});

const mapDispatchToProps = { getEntity,reset, getAttachments, getPlaylists, removePlaylist, removeAssociation };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoMetaDataDetail);
