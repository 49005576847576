import React, { useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router-dom';
import { Col, Label, Row } from 'reactstrap';
import { AvField, AvForm, AvGroup } from 'availity-reactstrap-validation';
import FormUpload from './admin-sparsh-management-track-upload';
import { getSpeakerList, getTrackDetailsByTrackId, uploadTrack,uploadTrackDetails } from './admin-sparsh-reducer';
import { toast } from 'react-toastify';
import CreatableSelect from 'react-select/creatable';
import JoditEditor from 'jodit-react';
// import { IRootState } from 'app/shared/reducers';


export interface IAdminSparshLyricsProps
  extends StateProps,
    DispatchProps,
    RouteComponentProps<{
      trackId: any;
      id?: string;
      association: string;
      url: string;
      liveVideoId: string;
      albumId: any;
    }> {}

export const AdminSparshTrackAdd = (props: IAdminSparshLyricsProps) => {
  // const { loading } = props;
  const { loading} = props;
  const [trackDetails, setTrackDetails] = useState<any>(null);
  const [isFileError, setFileError] = useState<boolean>(false);
  const [fileShow, setFileShow] = useState<any>(null);
  const [isPreview, setPreview] = useState<any>(null);
  const [file, setFile] = useState<any>(null);
  const [durationNumber, setNewDuration] = useState<number | null>(null);
  const [speakerList, setSpeakerList] = useState([]);
  const [speaker, setSpeaker] = useState(null);
  const editor = useRef(null);
  const [content, setContent] = useState('');
  const [lyrics, setLyrics] = useState(null);




  const options = [ 'bold', 'italic', '|', 'ul', 'ol', '|', 'font', 'fontsize', '|', 'outdent', 'indent', 'align', '|', 'hr', '|', 'fullsize', 'brush', '|', 'table', 'link', '|', 'undo', 'redo',];


  const config = useMemo(
    () => ({
      readonly: false, // all options from https://xdsoft.net/jodit/docs/,
      height: 500,
      width: 1500,
      placeholder: '',
      defaultActionOnPaste: 'insert_as_html',
      defaultLineHeight: 1.5,
      buttons: options,
      buttonsMD: options,
      buttonsSM: options,
      buttonsXS: options,
      toolbarAdaptive: false,
      showCharsCounter: false,
      showWordsCounter: false,
    }),
    []
  );


  useLayoutEffect(() => {
    const getAvailableSpeakerList = async () => {
      const data = await getSpeakerList();
      

      const formattedSpeakers = data.map((speaker, index) => {
        return { value: speaker, label: speaker };
      });

      setSpeakerList(formattedSpeakers);
    };

    getAvailableSpeakerList();
  }, []);

  const convertBase64 = (files: File) => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
        setFileShow(fileReader.result);
        setFileError(false);
      };

      fileReader.onerror = (error) => {
        reject(error);
        setFileError(true);
      };
    });
  };

  const uploadSingleFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e?.target?.files?.[0]) {
      const file = e.target.files[0];
      try {
        setPreview(true);
        setFile(file);
        const duration = await getMp3Duration(file);
        const durationNumber = Math.floor(Number(duration));
        setNewDuration(durationNumber);
        await convertBase64(file);
      } catch (error) {
        console.error(error);
      }
    }
  };


  const handleChangeSpeaker = (field, value) => {
    switch (field) {
      case 'options':
        setSpeaker(value);

        break;
      default:
        break;
    }
  };

  const getMp3Duration = (file: File) => {
    return new Promise<number>((resolve, reject) => {
      const audio = new Audio();
      audio.src = URL.createObjectURL(file);
      audio.onloadedmetadata = () => {
        resolve(audio.duration);
      };
      audio.onerror = () => {
        reject(new Error('Error loading audio file'));
      };
    });
  };

  const handleFormSubmitFile = async (event: React.FormEvent<HTMLFormElement>, values: any) => {
    // return
    if (!file) {
      toast.warn('Add an MP3 file!', { position: toast.POSITION.TOP_RIGHT });
      return;
    }
    const result = await props.uploadTrackDetails(values?.title , file ,values?.duration , lyrics, speaker?.value , props.match.params.albumId );
    if (result) {
      props.history.goBack();
    }
  };



  function onChangeHandler(lyrics: string): void {
    setLyrics(lyrics);
  }

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <button onClick={() => props.history.goBack()}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </button>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Add Track Details</h2>
            </div>
          </div>
        </Col>

        <Col md="12">
          <AvForm onValidSubmit={handleFormSubmitFile} >
            <div className="row">
              {/* Left Side */}
              <div className="col-md-6">
               

                <AvGroup className="input-box-width">
                  <Label id="title" for="title" className="label-font">Title*</Label>
                  <AvField
                    id="title"
                    type="text"
                    name="title"
                    className="input-font"
                    value={trackDetails?.title || ''}
                    validate={{
                      required: { value: true, errorMessage: 'Title is required' },
                      minLength: { value: 3, errorMessage: 'Title must be at least 3 characters' },
                      maxLength: { value: 100, errorMessage: 'Title must not exceed 100 characters' },
                    }}
                  />
                </AvGroup>

         


                  <AvGroup className="input-box-width">
                  <Label id="duration" for="duration" className="label-font">Duration in seconds*</Label>
                  <AvField
                    id="duration"
                    type="number"
                    name="duration"
                    className="input-font"
                    value={trackDetails?.duration || ''}
                    validate={{
                      required: { value: true, errorMessage: 'Duration is required' },
                      pattern: { value: '^[0-9]*$', errorMessage: 'Duration must be a number' },
                      custom: (value, ctx, input, cb) => {
                        cb(parseInt(value, 10) === durationNumber ? true : `Duration does not match the uploaded MP3 file (expected ${durationNumber} seconds).`);
                      },
                    }}
                  />
                </AvGroup>

              

                <div className="row" style={{ marginBottom: '40px' }}>
                  <div className="col-md-12">
                    <div className="file-container">
                      <FormUpload
                        label={<FontAwesomeIcon icon={faUpload} style={{ marginRight: '5px' }} />}
                        errorMsg={isFileError}
                        inlineLabel={false}
                        value={null}
                        style={{ height: 150 }}
                        uploadSingleFile={uploadSingleFile}
                      />
                      {fileShow && (
                        <FormUpload
                          isPreview={isPreview}
                          inlineLabel={false}
                          value={fileShow}
                          style={{ height: 50 }}
                          uploadSingleFile={uploadSingleFile}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>




              {/* Right Side */}
              <div className="col-md-6">
              <AvGroup className="input-box-width">
                    <Label id="speaker" for="speaker" className="label-font">
                      Artist
                    </Label>
                    {/* <AvField id="add2" type="text" name="add2" className="input-font" value={trackDetails?.playCount} disabled /> */}
                    <CreatableSelect
                      id="speaker"
                      name="speaker"
                      options={speakerList}
                      value={speaker}
                      className="input-font"
                      onChange={value => handleChangeSpeaker('options', value)}
                      // isMulti
                    />
                  </AvGroup>
              </div>

              
                <div className="col-md-12">
                <Label id="duration" for="duration" className="label-font">Add Lyrics</Label>
                  <JoditEditor
                    ref={editor}
                    value={content}
                    config={config}
                    onBlur={newContent => setContent(newContent)}
                    onChange={newContent => onChangeHandler(newContent)}
                  />
                </div>
              </div>

            <button
              // disabled={loading}
              id="save-entity"
              type="submit"
              className="common-btn"
              style={{ width: 200, marginTop: 20 }}
            >
              <span className="header-title">{loading ? 'Processing' : 'Submit'}</span>
              </button>
          </AvForm>
        </Col>
      </Row>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  loading: storeState.sparshAlbumManagement.loading,
});

const mapDispatchToProps = {
  getTrackDetailsByTrackId,
  uploadTrack,
  uploadTrackDetails,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdminSparshTrackAdd);
