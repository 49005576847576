import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Button, NavLink, Col, Row, Table } from 'reactstrap';
import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAllAction, Storage } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  // downloadVideoMetaData,
  // upcomingRenewalsVideoMetaData,
  // downloadVideoViewReportData,
  // downloadSubscriptionsReportData,
  // downloadMissingLinksData,
} from './reports.reducer';
import { ToastContainer, toast } from 'react-toastify';

import { IRootState } from 'app/shared/reducers';
import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { APP_DATE_FORMAT, APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import './reports.scss';
import { Label } from 'semantic-ui-react';
import { fontSize } from '@mui/system';

export interface IReportsProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const Reports = (props: IReportsProps) => {
  useEffect(() => {
    // props.getEntities();
  }, []);

  const [videoMetaDataFromDate, setVideoMetaDataFromDate] = useState(null);
  const [videoMetaDataToDate, setVideoMetaDataToDate] = useState(null);
  const [videoViewfromDate, setVideoViewFromDate] = useState(null);
  const [videoViewtoDate, setVideoViewtoDate] = useState(null);
  const [SubscriptionFromDate, setSubscriptionFromDate] = useState(null);
  const [SubscriptionToDate, setSubscriptionToDate] = useState(null);
  const [pendingRenwalToDate, setPendingRenwalToDate] = useState(null);
  const [pendingRenwalFromDate, setPendingRenwalFromDate] = useState(null);

  //BHAKTIPAD PLAY COUNT
  const [bhaktiPadPlayCountFromDate, setBhaktiPadPlayCountFromDate] = useState(null);
  const [bhaktiPadPlayCountEndDate, setBhaktiPadPlayCountEndDate] = useState(null);

  const [loading, setLoading] = useState(false)

  const now = new Date().toJSON().split('T')[0];
  // const sevenYearsAgo = new Date();
  // sevenYearsAgo.setMonth(sevenYearsAgo.getMonth() - 1);
  // const sevenYearsAgo1 = sevenYearsAgo.toJSON().split('T')[0];
  const date = new Date("2022-12-01");
  const formattedDate =
    // date.toLocaleString('default', { year: 'numeric' }) +
    // '-' +
    date.toLocaleString('default', { month: '2-digit' }) +
    '-' +
    date.toLocaleString('default', { day: '2-digit' }) +
    '-' +
    date.toLocaleString('default', { year: 'numeric' });

  // console.log("sevenYearsAgo",sevenYearsAgo);
  // console.log("formattedDate", formattedDate);


  const handleChangeFromDate = event => {
    setVideoMetaDataFromDate(event.target.value);
  };

  const handleChangeToDate = event => {
    setVideoMetaDataToDate(event.target.value);
  };

  const videoViewFromDate = event => {
    setVideoViewFromDate(event.target.value);
  };

  const videoViewToDate = event => {
    setVideoViewtoDate(event.target.value);
  };

  const subscriptionFromDate = event => {
    setSubscriptionFromDate(event.target.value);
  };

  const subscriptionToDate = event => {
    setSubscriptionToDate(event.target.value);
  };

  const handlePendingRenwalFromDate = event => {
    setPendingRenwalFromDate(event.target.value);
  };

  const handleBhaktiPadCountFromDate = event => {
    setBhaktiPadPlayCountFromDate(event.target.value);
  };

  const handleBhaktiPadCountEndDate = event => {
    setBhaktiPadPlayCountEndDate(event.target.value);
  };



  const handlePendingRenwalToDate = event => {
    setPendingRenwalToDate(event.target.value);
  };

  const [videoReportLoading, setVideoReportLoading] = useState(false)

  const [upcomingRenewalReportLoading, setPendingRenewalReportLoading] = useState(false)
  const [bhaktiPadPlayCountReportLoading, setBhaktiPadPlayCountReportLoading] = useState(false)


  const downloadVideoMetaData = () => {
    const postData = {
      startDate: videoMetaDataFromDate,
      endDate: videoMetaDataToDate,
    };
    setVideoReportLoading(true)
    const requestUrl = `/api/video-meta-data/details/reports`;
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postData),
    };
    fetch('api/video-meta-data/details/reports', requestOptions)
      .then(response => response.blob())
      .then(data => {
        setVideoReportLoading(false)
        if (data.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'VideoUploadReport.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });

        } else {
          setVideoReportLoading(false)
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  // const submitUpcomingRenewalData = () => {
  //   upcomingRenewalsVideoMetaData();
  // };


  const upcomingRenewalsVideoMetaData = async () => {
    setLoading(true)
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    // console.log('headers: ', headers);
    fetch('api/subscription-histories/upcomingRenavial/report', { headers })
      .then(response => response.blob())
      .then(data => {
        setLoading(false)
        if (data.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'UpcomingRenewalsReport.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };


  const downloadSubscriptionsReportData = async postData => {
    setLoading(true)
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postData),
    };
    fetch('api/subscription-histories/details/reports', requestOptions)
      .then(response => response.blob())
      .then(data => {
        setLoading(false)
        if (data.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'SubscriptionsReport.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const [allSubcriptionLoading, setAllSubcriptionLoading] = useState(false)
  const allSubscriptionsReportData = () => {
    const postData = {
      startDate: null,
      endDate: null,
    };
    setAllSubcriptionLoading(true)
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postData),
    };
    fetch('api/subscription-histories/details/reports', requestOptions)
      .then(response => response.blob())
      .then(data => {
        setAllSubcriptionLoading(false)
        if (data.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'SubscriptionsReport.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setAllSubcriptionLoading(false)
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  const subscriptionsReportData = () => {
    const postData = {
      startDate: SubscriptionFromDate,
      endDate: SubscriptionToDate,
    };
    downloadSubscriptionsReportData(postData);
  };

  const [activeIndex, setActiveIndex] = useState(null);

  const [videoViewReportLoading, setVideoViewReportLoading] = useState(false)
  const downloadVideoViewReportData = () => {
    setVideoViewReportLoading(true)
    const postData = {
      startDate: videoViewfromDate,
      endDate: videoViewtoDate,
    };
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postData),
    };
    fetch('api/video-view-histories/details/reports', requestOptions)
      .then(response => response.blob())
      .then(data => {
        setVideoViewReportLoading(false)
        if (data.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'VideoViewReport.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setVideoViewReportLoading(false)
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };


  const downloadPendingRenewalReports = () => {
    const postData = {
      startDate: pendingRenwalFromDate,
      endDate: pendingRenwalToDate,
    };
    setPendingRenewalReportLoading(true)
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postData),
    };
    fetch('api/subscription-histories/end-date-between/reports', requestOptions)
      .then(response => response.blob())
      .then(data => {
        setPendingRenewalReportLoading(false)
        if (data.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'PendingRenewalReport.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setPendingRenewalReportLoading(false)
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  //DOWNLOAD PLAY COUNT REPORT
  const downloadBhaktiPadPlayCountReports = () => {
    const fromDate = new Date(bhaktiPadPlayCountFromDate);
    const toDate = new Date(bhaktiPadPlayCountEndDate);
  
    if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
      toast.error('Invalid date(s) provided.', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }
  
    fromDate.setUTCHours(0, 0, 1, 0);
    
    toDate.setUTCHours(23, 59, 59, 999);
  
    const postData = {
      fromDate: fromDate.toISOString().split('.')[0] + 'Z',
      toDate: toDate.toISOString().split('.')[0] + 'Z',
    };
  
    setBhaktiPadPlayCountReportLoading(true);
  
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
  
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postData),
    };
  
    fetch('api/v3/youtube-audio-metadata/song-play-count-between/reports', requestOptions)
      .then(response => response.blob())
      .then(data => {
        setBhaktiPadPlayCountReportLoading(false);
        if (data.type === "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'bhaktiPadPlayCountsReport.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded!', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setBhaktiPadPlayCountReportLoading(false);
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      })
      .catch(error => {
        setBhaktiPadPlayCountReportLoading(false);
        toast.error('Failed to download the report. Please try again later.', {
          position: toast.POSITION.TOP_RIGHT,
        });
        console.error('Download error:', error);
      });
  };
  
  
  
  
  



  const [missingLinksLoading, setMissingLinksLoading] = useState(false)
  const downloadMissingLinksData = async () => {
    setMissingLinksLoading(true)
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    // console.log('headers: ', headers);
    fetch('api/youtube-sync-data/details/reports', { headers })
      .then(response => response.blob())
      .then(data => {
        setMissingLinksLoading(false)
        if (data.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'MissingLinksReport.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setMissingLinksLoading(false)
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }

      });
  };

  const [userListLoading, setUserListLoading] = useState(false)

  const downloadUserListData = () => {
    setUserListLoading(true)
    const postData = {
     
    };
    const anchor = document.createElement('a');
    let headers = new Headers();
    const token = Storage.local.get('jhi-authenticationToken') || Storage.session.get('jhi-authenticationToken');
    headers.append('Authorization', `Bearer ${token}`);
    headers.append('Content-Type', 'application/json');
    const requestOptions = {
      method: 'POST',
      headers: headers,
      body: JSON.stringify(postData),
    };
    fetch('api/user/details/reports', requestOptions)
      .then(response => response.blob())
      .then(data => {
        setUserListLoading(false)
        if (data.type == "application/octet-stream") {
          let objectUrl = window.URL.createObjectURL(data);
          anchor.href = objectUrl;
          anchor.download = 'UserList.xlsx';
          anchor.click();
          anchor.remove();
          window.URL.revokeObjectURL(objectUrl);
          toast.success('Successfully downloaded !', {
            position: toast.POSITION.TOP_RIGHT,
          });
        } else {
          setUserListLoading(false)
          toast.error('Something went wrong', {
            position: toast.POSITION.TOP_RIGHT,
          });
        }
      });
  };

  return (
    <>
      <div onClick={() => setActiveIndex(0)} className="main-container" style={{ backgroundColor: '#E8E8E8', margin: 26 }}>
        <div className="row main-container">
          <Col md="12">
            <div className="row">
              <div className="col-md-4">
                <h2 id="report-heading" style={{ color: 'rgb(114 108 108)', marginTop: 15 }}>
                  "Video Uploads" Report
                </h2>
              </div>
            </div>
          </Col>
        </div>
        {activeIndex == 0 && (
          <div className="row main-container">
            <Col md="12">
              <AvForm>
                <div className="row">
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="startDate" for="fromdate" className="label-font">
                        From Date
                      </Label>
                      <AvField
                        id="startDate"
                        type="date"
                        name="startDate"
                        className="input-font"
                        max={new Date().toISOString().slice(0, 10)}
                        value={videoMetaDataFromDate}
                        onChange={handleChangeFromDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="endDate" for="fromdate" className="label-font">
                        To Date
                      </Label>
                      <AvField
                        id="endDate"
                        type="date"
                        name="endDate"
                        className="input-font"
                        min={videoMetaDataFromDate}
                        max={new Date().toISOString().slice(0, 10)}
                        // min={"2022-11-31"}
                        // max={now}
                        value={videoMetaDataToDate}
                        onChange={handleChangeToDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-4 text-center" style={{ marginTop: 35 }}>
                    {/* <Button color="primary" className="input-font" onClick={() => submitData()}>
                      Download
                    </Button> */}
                    {/* {videoMetaDataFromDate && videoMetaDataToDate && ( */}
                    <button
                      // disabled={isInvalid || updating}
                      id="save-entity"
                      type="submit"
                      className={videoMetaDataFromDate && videoMetaDataToDate ? 'common-btn' : 'common-btn-disabled'}
                      disabled={!(videoMetaDataFromDate && videoMetaDataToDate)}
                      style={{
                        width: 200,
                        marginTop: 0,
                      }}
                      onClick={() => downloadVideoMetaData()}
                    >
                      <span className="header-title">{videoReportLoading ? 'Processing' : 'Download'}</span>
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </AvForm>
            </Col>
          </div>
        )}
      </div>
      <div onClick={() => setActiveIndex(1)} className="main-container" style={{ backgroundColor: '#E8E8E8', margin: 26 }}>
        <div className="row main-container">
          <Col md="12">
            <div className="row">
              <div className="col-md-4" style={{ display: 'inline' }}>
                <div>
                  <h2 id="report-heading" style={{ color: 'rgb(114 108 108)', marginTop: 15 }}>
                    "Subscriptions" Report
                  </h2>
                </div>
              </div>
            </div>
          </Col>
        </div>
        {activeIndex == 1 && (
          <div className="row main-container">
            <Col md="12">
              <AvForm>
                <div className="row">
                  <div className="col-md-6">
                    <h2 style={{ color: 'rgb(114 108 108)', fontSize: '28px', margin: 20 }}>All Time Subscription Report</h2>
                  </div>
                  {/* <div className="col-md-3"></div> */}
                  <div className="col-md-3"></div>
                  <div className="col-md-3 text-center">
                    <button
                      disabled={allSubcriptionLoading ? true : false}
                      id="save-entity"
                      type="submit"
                      className="common-btn"
                      style={{
                        width: 200,
                        marginTop: 30,
                      }}
                      onClick={() => allSubscriptionsReportData()}
                    >
                      <span className="header-title">{allSubcriptionLoading ? 'Processing' : 'Download'}</span>
                    </button>
                  </div>
                </div>
                <hr />
                <div className="row">
                  <div className="col-md-3">
                    <h2 style={{ color: 'rgb(114 108 108)', fontSize: '28px', margin: 20 }}>Date Wise Report</h2>
                  </div>
                  <div className="col-md-3">
                    <AvGroup className="input-box-width">
                      <Label id="startDate" for="fromdate" className="label-font">
                        From Date
                      </Label>
                      <AvField
                        id="startDate"
                        type="date"
                        name="startDate"
                        className="input-font"
                        max={new Date().toISOString().slice(0, 10)}
                        value={SubscriptionFromDate}
                        onChange={subscriptionFromDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-3">
                    <AvGroup className="input-box-width">
                      <Label id="endDate" for="fromdate" className="label-font">
                        To Date
                      </Label>
                      <AvField
                        id="endDate"
                        type="date"
                        name="endDate"
                        className="input-font"
                        min={SubscriptionFromDate}
                        max={new Date().toISOString().slice(0, 10)}
                        value={SubscriptionToDate}
                        onChange={subscriptionToDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-3 text-center" style={{ marginTop: 35 }}>
                    {/* <Button color="primary" className="input-font" onClick={() => submitViewReportData()}>
                      Download
                    </Button> */}

                    {/* {SubscriptionFromDate && SubscriptionToDate && ( */}
                    <button
                      // disabled={isInvalid || updating}
                      id="save-entity"
                      type="submit"
                      className={SubscriptionFromDate && SubscriptionToDate ? 'common-btn' : 'common-btn-disabled'}
                      disabled={!(SubscriptionFromDate && SubscriptionToDate)}
                      style={{
                        width: 200,
                        marginTop: 0,
                      }}
                      onClick={() => subscriptionsReportData()}
                    >
                      <span className="header-title">{loading ? 'Processing' : 'Download'}</span>
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </AvForm>
            </Col>
          </div>
        )}
      </div>
      <div onClick={() => setActiveIndex(2)} className="main-container" style={{ backgroundColor: '#E8E8E8', margin: 26 }}>
        <div className="row main-container">
          <Col md="12">
            <div className="row">
              <div className="col-md-4 ">
                <h2 id="report-heading" className="input-box-width" style={{ color: 'rgb(114 108 108)', marginTop: 15 }}>
                  "Upcoming Renewals" Report
                </h2>
              </div>
              <div className="col-md-4"></div>
              {activeIndex == 2 && (
                <div className="col-md-4 text-center" style={{ marginTop: 35 }}>
                  <button
                    disabled={loading ? true : false}
                    id="save-entity"
                    type="submit"
                    className="common-btn"
                    style={{
                      width: 200,
                      marginTop: 0,
                      marginBottom: 15,
                    }}
                    onClick={() => upcomingRenewalsVideoMetaData()}
                  >
                    <span className="header-title">{loading ? 'Processing' : 'Download'}</span>
                  </button>
                </div>
              )}
            </div>
          </Col>
        </div>
      </div>
      <div onClick={() => setActiveIndex(3)} className="main-container" style={{ backgroundColor: '#E8E8E8', margin: 26 }}>
        <div className="row main-container">
          <Col md="12">
            <div className="row">
              <div className="col-md-4">
                <h2 id="report-heading" style={{ color: 'rgb(114 108 108)', marginTop: 15 }}>
                  "View Patterns" Report
                </h2>
              </div>
            </div>
          </Col>
        </div>
        {activeIndex == 3 && (
          <div className="row main-container">
            <Col md="12">
              <AvForm>
                <div className="row">
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="startDate" for="fromdate" className="label-font">
                        From Date
                      </Label>
                      <AvField
                        id="startDate"
                        type="date"
                        name="startDate"
                        className="input-font"
                        max={new Date().toISOString().slice(0, 10)}
                        value={videoViewfromDate}
                        onChange={videoViewFromDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="endDate" for="fromdate" className="label-font">
                        To Date
                      </Label>
                      <AvField
                        id="endDate"
                        type="date"
                        name="endDate"
                        className="input-font"
                        min={videoViewfromDate}
                        max={new Date().toISOString().slice(0, 10)}
                        value={videoViewtoDate}
                        onChange={videoViewToDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-4 text-center" style={{ marginTop: 35 }}>
                    {/* <Button color="primary" className="input-font" onClick={() => videoViewReportData()}>
                      Download
                    </Button> */}
                    {/* {videoViewfromDate && videoViewtoDate && ( */}
                    <button
                      // disabled={isInvalid || updating}
                      id="save-entity"
                      type="submit"
                      // className='common-btn'
                      className={videoViewfromDate && videoViewtoDate ? 'common-btn' : 'common-btn-disabled'}
                      disabled={!(videoViewfromDate && videoViewtoDate)}
                      style={{
                        width: 200,
                        marginTop: 0,
                      }}
                      onClick={() => downloadVideoViewReportData()}
                    >
                      <span className="header-title">{videoViewReportLoading ? 'Processing' : 'Download'}</span>
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </AvForm>
            </Col>
          </div>
        )}
      </div>
      <div onClick={() => setActiveIndex(4)} className="main-container" style={{ backgroundColor: '#E8E8E8', margin: 26 }}>
        <div className="row main-container">
          <Col md="12">
            <div className="row">
              <div className="col-md-4 ">
                <h2 id="report-heading" className="input-box-width" style={{ color: 'rgb(114 108 108)', marginTop: 15 }}>
                  "Missing Links " Report
                </h2>
              </div>
              <div className="col-md-4"></div>
              {activeIndex == 4 && (
                <div className="col-md-4 text-center" style={{ marginTop: 35 }}>
                  <button
                    disabled={missingLinksLoading ? true : false}
                    id="save-entity"
                    type="submit"
                    className="common-btn"
                    style={{
                      width: 200,
                      marginTop: 0,
                      marginBottom: 15,
                    }}
                    onClick={() => downloadMissingLinksData()}
                  >
                    <span className="header-title">{missingLinksLoading ? 'Processing' : 'Download'}</span>
                  </button>
                </div>
              )}
            </div>
          </Col>
        </div>
      </div>

      <div onClick={() => setActiveIndex(5)} className="main-container" style={{ backgroundColor: '#E8E8E8', margin: 26 }}>
        <div className="row main-container">
          <Col md="12">
            <div className="row">
              <div className="col-md-4 ">
                <h2 id="report-heading" className="input-box-width" style={{ color: 'rgb(114 108 108)', marginTop: 15 }}>
                  "User List " Report
                </h2>
              </div>
              <div className="col-md-4"></div>
              {activeIndex == 5 && (
                <div className="col-md-4 text-center" style={{ marginTop: 35 }}>
                  <button
                    disabled={userListLoading ? true : false}
                    id="save-entity"
                    type="submit"
                    className="common-btn"
                    style={{
                      width: 200,
                      marginTop: 0,
                      marginBottom: 15,
                    }}
                    onClick={() => downloadUserListData()}
                  >
                    <span className="header-title">{userListLoading ? 'Processing' : 'Download'}</span>
                  </button>
                </div>
              )}
            </div>
          </Col>
        </div>
      </div>

      <div onClick={() => setActiveIndex(6)} className="main-container" style={{ backgroundColor: '#E8E8E8', margin: 26 }}>
        <div className="row main-container">
          <Col md="12">
            <div className="row">
              <div className="col-md-4">
                <h2 id="report-heading" style={{ color: 'rgb(114 108 108)', marginTop: 15 }}>
                  "Pending Renewals" Report
                </h2>
              </div>
            </div>
          </Col>
        </div>
        {activeIndex == 6 && (
          <div className="row main-container">
            <Col md="12">
              <AvForm>
                <div className="row">
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="startDate" for="fromdate" className="label-font">
                        From Date
                      </Label>
                      <AvField
                        id="startDate"
                        type="date"
                        name="startDate"
                        className="input-font"
                        // max={new Date().toISOString().slice(0, 10)}
                        value={pendingRenwalFromDate}
                        onChange={handlePendingRenwalFromDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="endDate" for="fromdate" className="label-font">
                        To Date
                      </Label>
                      <AvField
                        id="endDate"
                        type="date"
                        name="endDate"
                        className="input-font"
                        min={pendingRenwalFromDate}
                        // max={new Date().toISOString().slice(0, 10)}
                        value={pendingRenwalToDate}
                        onChange={handlePendingRenwalToDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-4 text-center" style={{ marginTop: 35 }}>
                    <button
                      id="save-entity"
                      type="submit"
                      className={pendingRenwalFromDate && pendingRenwalToDate ? 'common-btn' : 'common-btn-disabled'}
                      disabled={!(pendingRenwalFromDate && pendingRenwalToDate)}
                      style={{
                        width: 200,
                        marginTop: 0,
                      }}
                      onClick={() => downloadPendingRenewalReports()}
                    >
                      <span className="header-title">{upcomingRenewalReportLoading ? 'Processing' : 'Download'}</span>
                    </button>
                  </div>
                </div>
              </AvForm>
            </Col>
          </div>
        )}
      </div>

      
      <div onClick={() => setActiveIndex(7)} className="main-container" style={{ backgroundColor: '#E8E8E8', margin: 26 }}>
        <div className="row main-container">
          <Col md="12">
            <div className="row">
              <div className="col-md-4">
                <h2 id="report-heading" style={{ color: 'rgb(114 108 108)', marginTop: 15 }}>
                  "Bhakti Pad Play Count" Report
                </h2>
              </div>
            </div>
          </Col>
        </div>
        {activeIndex == 7 && (
          <div className="row main-container">
            <Col md="12">
              <AvForm>
                <div className="row">
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="startDate" for="fromdate" className="label-font">
                        From Date
                      </Label>
                      <AvField
                        id="startDate"
                        type="date"
                        name="startDate"
                        className="input-font"
                        // max={new Date().toISOString().slice(0, 10)}
                        value={bhaktiPadPlayCountFromDate}
                        onChange={handleBhaktiPadCountFromDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-4">
                    <AvGroup className="input-box-width">
                      <Label id="endDate" for="fromdate" className="label-font">
                        To Date
                      </Label>
                      <AvField
                        id="endDate"
                        type="date"
                        name="endDate"
                        className="input-font"
                        min={bhaktiPadPlayCountFromDate}
                        max={new Date().toISOString().slice(0, 10)}
                        value={bhaktiPadPlayCountEndDate}
                        onChange={handleBhaktiPadCountEndDate}
                      />
                    </AvGroup>
                  </div>
                  <div className="col-md-4 text-center" style={{ marginTop: 35 }}>
                    <button
                      id="save-entity"
                      type="submit"
                      className={bhaktiPadPlayCountFromDate && bhaktiPadPlayCountEndDate ? 'common-btn' : 'common-btn-disabled'}
                      disabled={!(bhaktiPadPlayCountFromDate && bhaktiPadPlayCountEndDate)}
                      style={{
                        width: 200,
                        marginTop: 0,
                      }}
                      onClick={() => downloadBhaktiPadPlayCountReports()}
                    >
                      <span className="header-title">{bhaktiPadPlayCountReportLoading ? 'Processing' : 'Download'}</span>
                    </button>
                  </div>
                </div>
              </AvForm>
            </Col>
          </div>
        )}
      </div>


      



    </>
  );
};

const mapStateToProps = ({ subscriptionPlan }: IRootState) => ({
  subscriptionPlanList: subscriptionPlan.entities,
  loading: subscriptionPlan.loading,
});

const mapDispatchToProps = {
  //   getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Reports);
