/* eslint-disable no-console */
import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, NavLink, Col, Label } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { getEntities as getSubscriptionPlans } from 'app/entities/subscription-plan/subscription-plan.reducer';
import { IStatuses } from 'app/shared/model/statuses.model';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { getEntity, updateEntity, createEntity, reset } from './video-meta-data.reducer';
import { IVideoMetaData } from 'app/shared/model/video-meta-data.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { APP_LOCAL_DATETIME_DAY, APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'app/config/constants';

export interface IVideoMetaDataUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const VideoMetaDataUpdate = (props: IVideoMetaDataUpdateProps) => {
  const [subscriptionPlanId, setSubscriptionPlanId] = useState('0');
  const [statusesId, setStatusesId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [keyword, setKeyword] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [description, setDescription] = useState('');

  const { videoMetaDataEntity, subscriptionPlans, statuses, loading, updating } = props;
  // const options = [
  //   { value: 1, label: 'Orange' },
  //   { value: 2, label: 'Black' },
  //   { value: 3, label: 'Brown' },
  //   { value: 4, label: 'Violet' },
  //   { value: 5, label: 'Green' },
  // ];

  const handleChangeDescription = event => {
    setDescription(event.target.value);
  };

  useEffect(() => {
    setKeyword([]);
    setTimeout(() => {
      keywordFunList();
      keywordFun();
    }, 1000);

    const keywordFun = () => {
      if (videoMetaDataEntity.keywords) {
        const array = videoMetaDataEntity?.keywords?.split(',');
        const keys = keyword;
        array?.map((item, index) => {
          return keys.push({ value: index, label: item });
        });
        setKeyword(keys);
      } else {
        setKeyword([]);
      }
    };
  }, [videoMetaDataEntity, isNew]);

  const keywordFunList = () => {
    if (videoMetaDataEntity?.keywordList) {
      const keys = keywordList;
      videoMetaDataEntity?.keywordList?.map((item, index) => {
        return keys.push({ value: index, label: item });
      });
      setKeywordList(keys);
    } else {
      setKeywordList([]);
    }
  };

  const handleChange = (field, value) => {
    switch (field) {
      case 'options':
        setKeyword(value);
        break;
      default:
        break;
    }
  };

  const handleClose = () => {
    props.history.push('/video-meta-data' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getSubscriptionPlans();
    props.getStatuses();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      handleClose();
    }
  }, [props.updateSuccess]);

  const saveEntity = (event, errors, values) => {
    const date = new Date();
    const formattedDate =
      // date.toLocaleString('default', { year: 'numeric' }) +
      // '-' +
      date.toLocaleString('default', { month: '2-digit' }) +
      '-' +
      date.toLocaleString('default', { day: '2-digit' }) +
      '-' +
      date.toLocaleString('default', { year: 'numeric' });

    // updatedDate: formattedDate
    const key = Object.values(
      keyword?.map(item => {
        return item.label;
      })
    )?.join();
    values = { ...values, crowed:values.crowed=="true"?true:false, keywords: key, videotype: values.videoType, serialNumber: values.serialNumber };

    if (errors.length === 0) {
      const entity = {
        ...videoMetaDataEntity,
        ...values,
      };
      if (isNew) {
        props.createEntity(entity);
      } else {
        props.updateEntity(entity);
      }
    }
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <NavLink tag={Link} to={'/video-meta-data' + props.location.search}>
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </NavLink>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Edit Video Meta Data</h2>
            </div>
          </div>
        </Col>
      </Row>
      <div className="row main-container">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : videoMetaDataEntity} onSubmit={saveEntity}>
              <div className="row">
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="videoUrlLabel" for="video-meta-data-videoUrl" className="label-font">
                      Video Url
                    </Label>
                    <AvField id="video-meta-data-videoUrl" type="text" name="videoUrl" className="input-font" disabled />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="videoUrlTitle" for="video-meta-data-videoTitle" className="label-font">
                      Video Title
                    </Label>
                    <AvField id="video-meta-data-videoTitle" type="text" name="videoTitle" className="input-font" />
                  </AvGroup>
                </div>

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="videoId" for="video-meta-data-videoId" className="label-font">
                      VideoId
                    </Label>
                    <AvField id="video-meta-data-videoId" type="text" name="videoId" className="input-font" disabled />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="videoRecordDate" for="video-meta-data-videoRecordDate" className="label-font">
                      Video Record Date
                    </Label>
                    <AvField id="video-meta-data-videoRecordDate" type="date" className="form-control input-font " name="videoRecordDate" />
                    {/* <TextFormat type="date" id="video-meta-data-videoRecordDate"  className="form-control input-font " name="videoRecordDate" format={APP_LOCAL_DATETIME_DAY} /> */}
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="channelName" for="video-meta-data-channelName" className="label-font">
                      Channel Name
                    </Label>
                    <AvField id="video-meta-data-channelName" type="text" name="channelName" className="input-font" />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label for="video-meta-data-statuses" className="label-font">
                      Status
                    </Label>
                    <AvInput id="video-meta-data-statuses" type="select" className="form-control input-font" name="statuses.id">
                      {/* <option value="" key="0" /> */}

                      {statuses
                        ? statuses.map(otherEntity => (
                            <option value={otherEntity.id} key={otherEntity.id}>
                              {otherEntity.statusValue}
                            </option>
                          ))
                        : null}
                    </AvInput>
                  </AvGroup>
                </div>

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="category" for="video-meta-data-category" className="label-font">
                      Category
                    </Label>
                    <AvField id="video-meta-data-category" type="text" name="category" className="input-font" />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="audience" for="video-meta-data-audience" className="label-font">
                      Audience
                    </Label>
                    <AvField id="video-meta-data-audience" type="text" name="audience" className="input-font" />
                  </AvGroup>
                </div>

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="language" for="video-meta-data-language" className="label-font">
                      Language
                    </Label>
                    <AvField id="video-meta-data-language" type="text" name="language" className="input-font" />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="location" for="video-meta-data-location" className="label-font">
                      Location
                    </Label>
                    <AvField id="video-meta-data-location" type="text" name="location" className="input-font" />
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="videotypeLabel" for="video-meta-data-videotype" className="label-font">
                      Video Type
                    </Label>
                    <AvField id="video-meta-data-videotype" type="text" name="videoType" className="input-font" />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label for="video-meta-data-subscriptionPlan" className="label-font">
                      Subscription Plan
                    </Label>
                    <AvInput
                      id="video-meta-data-subscriptionPlan"
                      type="select"
                      className="form-control input-font"
                      name="subscriptionPlan.id"
                    >
                      <option value="" key="0" />
                      {subscriptionPlans
                        ? subscriptionPlans.map(otherEntity => (
                            <>
                              {otherEntity?.statuses?.statusValue === 'ACTIVE' ? (
                                <option value={otherEntity.id} key={otherEntity.id}>
                                  {otherEntity.subscriptionName}
                                </option>
                              ) : (
                                ''
                              )}
                            </>
                          ))
                        : null}
                    </AvInput>
                  </AvGroup>
                </div>

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="speaker" for="video-meta-data-speaker" className="label-font">
                      Speaker
                    </Label>
                    <AvField id="video-meta-data-speaker" type="text" name="speaker" className="input-font" />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="publishDateLabel" for="video-meta-data-publishDate" className="label-font">
                      Publish Date
                    </Label>
                    <AvField id="video-meta-data-publishDate" type="date" className="form-control input-font" name="publishDate" disabled />
                  </AvGroup>
                </div>

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="updatedDateLabel" for="video-meta-data-updatedDate" className="label-font">
                      Last Updated Date
                    </Label>
                    <AvField
                      id="video-meta-data-updatedDate"
                      // type="date"
                      className="form-control input-font"
                      name="updatedDate"
                      format={APP_LOCAL_DATE_FORMAT}
                      // value={updatedDate}
                      disabled
                    />
                    {/* <TextFormat className="form-control input-font " type="date"  name="updatedDate" value={updatedDate} format={APP_LOCAL_DATE_FORMAT} /> */}
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="keywordsLabel" for="video-meta-data-keywords" className="label-font">
                      Keywords
                    </Label>
                    <CreatableSelect
                      className="input-font"
                      id="video-meta-data-keywords"
                      name="keywords"
                      onChange={value => handleChange('options', value)}
                      isMulti
                      options={keywordList}
                      // options={options}
                      value={keyword}
                    />

                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label for="video-meta-data-crowed" className="label-font">
                      Crowd Sourcing
                    </Label>
                    <AvInput id="video-meta-data-crowed" type="select" className="form-control input-font" name="crowed">
                      <option value="" key="0" />

                      <option value={"true"} key={1}>
                        On
                      </option>
                      <option value={"false"} key={2}>
                        Off
                      </option>
                    </AvInput>
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="serialNumberLabel" for="video-meta-data-serialNumber" className="label-font">
                      Serial Number
                    </Label>
                    <AvField id="video-meta-data-serialNumber" type="text" className="form-control input-font" name="serialNumber" />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="videoDescription" for="video-meta-data-videoDescription" className="label-font">
                      Video Description
                    </Label>
                    <AvField
                      id="video-meta-data-videoDescription"
                      type="textarea"
                      cols="45"
                      rows="5"
                      name="videoDescription"
                      className="input-font"
                    />
                  </AvGroup>
                </div>
                {/* <div className="col-md-4">
                 
                </div> */}
                {/* <Button tag={Link} id="cancel-save" to="/video-meta-data" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button> */}
                {/* &nbsp; */}
                <button
                  disabled={updating}
                  id="save-entity"
                  type="submit"
                  className="common-btn"
                  style={{
                    width: 200,
                    marginTop: 20,
                    marginLeft: 15,
                  }}
                >
                  <span className="header-title">Save</span>
                </button>
              </div>
            </AvForm>
          )}
        </Col>
      </div>
    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  subscriptionPlans: storeState.subscriptionPlan.entities,
  statuses: storeState.statuses.entities,
  videoMetaDataEntity: storeState.videoMetaData.entity,
  loading: storeState.videoMetaData.loading,
  updating: storeState.videoMetaData.updating,
  updateSuccess: storeState.videoMetaData.updateSuccess,
});

const mapDispatchToProps = {
  getSubscriptionPlans,
  getStatuses,
  getEntity,
  updateEntity,
  createEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoMetaDataUpdate);
