import { Moment } from 'moment';
import { IUser } from './user.model';

export interface ICrowdSourcing {
  id?: number;
  videoTitle?: string;
  videoUrl?: string;
  reportedBy?: IUser;
  timeframe?: string;
  incidentType?: string;
  incidentWord?: string;
  createdBy?: string;
  createdDate?: string;
  status?: string;
  contentId?: number;
  videoUserMapping?: boolean;
  adminRemarks?: string;
  reviewerRemarks?: string;
  startTimeFrame?: string;
  endTimeFrame?: string;
}

export const defaultValue: Readonly<ICrowdSourcing> = {};
