import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import './crowd-sourcing.scss';
import { NavLink, Col, Label } from 'reactstrap';
import { AvGroup, AvInput, AvFeedback } from 'availity-reactstrap-validation';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { IRootState } from 'app/shared/reducers';
import {
  getEntity,
  blockUser,
  unBlockUser,
  saveAdminReview,
  saveReviewerReview,
  deleteAdminReview,
  deleteReviewerReview,
} from './crowd-sourcing.reducer';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';
import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';

export interface ICrowdSourcingDetailProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {}

export const CrowdSourcingDetails = (props: ICrowdSourcingDetailProps) => {
  const [show, setShow] = useState<boolean>(false);
  const [currentTimeout, setCurrentTimeout] = useState(null);
  const [editAdminsRemark, setEditAdminsRemark] = useState<boolean>(false);

  const [editReviewersRemark, setEditReviewersRemark] = useState<boolean>(false);

  const [reviewState, setReviewState] = useState<{ adminRemark: string; reviewerRemark: string; status: string }>(null);
  const { incidentEntity: incidentEntity, loading, totalItems, updating, isAdmin, isReviewer } = props;
  console.log('adminPlaylistEntity: ', incidentEntity);

  useEffect(() => {
    props.getEntity(props?.match?.params?.id);

    currentTimeout && clearTimeout(currentTimeout);
    setCurrentTimeout(null);
    const timeout = setTimeout(() => {
      setShow(true);
    }, 150);
    setCurrentTimeout(timeout);
  }, [props?.match?.params?.id]);

  useEffect(() => {
    setReviewState({
      adminRemark: incidentEntity.adminRemarks,
      reviewerRemark: incidentEntity.reviewerRemarks,
      status: incidentEntity.status,
    });
  }, [incidentEntity]);

  const handleBlockingUser = () => {
    props.blockUser(incidentEntity.reportedBy.id, props?.match?.params?.id);
  };
  const handleUnBlockingUser = () => {
    props.unBlockUser(incidentEntity.reportedBy.id, props?.match?.params?.id);
  };
  const handleAdminsRemark = (remark: string) => {
    setReviewState(prev => ({ ...prev, adminRemark: remark }));
  };
  const handleReviewersRemark = (remark: string) => {
    setReviewState(prev => ({ ...prev, reviewerRemark: remark }));
  };
  const handleStatus = (status: string) => {
    setReviewState(prev => ({ ...prev, status: status }));
  };
  const handleToggleEditAdminRemark = () => {
    if (!editAdminsRemark) {
      setReviewState(prev => ({ ...prev, adminRemark: incidentEntity.adminRemarks, status: incidentEntity.status }));
    }
    setEditAdminsRemark(prev => !prev);
  };
  const validateInput = (input: string): string | null => {
    // Check if input is not present
    if (!input) {
      return 'Input cannot be empty.';
    }

    // Check if input contains only numbers
    if (/^\d+$/.test(input)) {
      return 'Input cannot contain only numbers.';
    }

    // Check if input contains only special characters
    if (/^[^\w\s]+$/.test(input)) {
      return 'Input cannot contain only special characters.';
    }

    // Valid input
    return null;
  };
  const handleToggleEditReviewesRemark = () => {
    if (!editReviewersRemark) {
      setReviewState(prev => ({ ...prev, reviewerRemark: incidentEntity.reviewerRemarks, status: incidentEntity.status }));
    }
    setEditReviewersRemark(prev => !prev);
  };
  const handleSaveAdminsRemark = (status: string) => {
    let result;
    const inputError = validateInput(reviewState.adminRemark);

    if (inputError) {
      // Show toast error message for the specific condition
      toast.error(inputError);

      // Return or perform other actions based on your requirements
      return;
    }
    try {
      const param = { id: incidentEntity.id, status: status, adminRemarks: reviewState.adminRemark };

      result = props.saveAdminReview(param, props?.match?.params?.id);
    } catch (error) {
      setEditAdminsRemark(false);
    }
    if (result) {
      setEditAdminsRemark(false);
    }
  };
  const handleSaveReviewersRemark = (status: string) => {
    let result;
    const inputError = validateInput(reviewState.reviewerRemark);

    if (inputError) {
      // Show toast error message for the specific condition
      toast.error(inputError);

      // Return or perform other actions based on your requirements
      return;
    }
    try {
      const param = { id: incidentEntity.id, status: status, reviewerRemarks: reviewState.reviewerRemark };

      result = props.saveReviewerReview(param, props?.match?.params?.id);
    } catch (error) {
      setEditReviewersRemark(false);
    }
    if (result) {
      setEditReviewersRemark(false);
    }
  };
  const handleDeleteReviewerRemark = () => {
    try {
      props.deleteReviewerReview(props?.match?.params?.id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleDeleteAdminRemark = () => {
    try {
      props.deleteAdminReview(props?.match?.params?.id);
    } catch (error) {
      console.log(error);
    }
  };
  const handleUrlClick = (url: string) => {
    window.open(url, '_blank');
  };
  // const renderReviewSection = (
  //   remarks: string | undefined,
  //   status: string | undefined,
  //   updating: boolean,
  //   handleToggleEdit: () => void,
  //   handleStatusChange: (status: string) => void,
  //   handleRemarkChange: (remark: string) => void,
  //   handleSave: () => void,
  //   isAdmin: boolean,
  //   isReviewer: boolean
  // ) => {
  //   return (
  //     <div className="remark-container">
  //       <div className="remark-content">
  //         <Label for="adminRemark" className="label-font">
  //           Remark
  //         </Label>
  //         <textarea name="adminRemark" id="adminRemark" rows={5} value={remarks} onChange={e => handleRemarkChange(e.target.value)} />
  //       </div>
  //       <div className="remark-content">
  //         <Label for="incidentStatus" className="label-font">
  //           Incident Status
  //         </Label>

  //         <select
  //           value={status}
  //           onChange={e => handleStatusChange(e.target.value)}
  //           disabled={status === 'REVIEW_COMPLETED' && isAdmin}
  //           id="status"
  //           className="form-control input-font"
  //         >
  //           <option value="" key="0" label="Select" />

  //           {status == 'REPORTED' && isAdmin ? null :<option  value={'REPORTED'} key={'0'}>
  //             {'REPORTED'}
  //           </option>}
  //           <option value={'UNDER_REVIEW'} key={'1'}>
  //             {'UNDER_REVIEW'}
  //           </option>
  //           {status != 'REVIEW_COMPLETED' && isAdmin ? null : (
  //             <option value={'REVIEW_COMPLETED'} key={'3'}>
  //               {'REVIEW_COMPLETED'}
  //             </option>
  //           )}
  //         </select>
  //       </div>
  //       <div className="remark-content">
  //         <div className="button-container">
  //           <button
  //             disabled={updating}
  //             id="close-entity"
  //             type="button"
  //             onClick={handleToggleEdit}
  //             className="common-btn"
  //             style={{
  //               width: 'fit-content',
  //               marginTop: 20,
  //               padding: 5,
  //             }}
  //           >
  //             <span className="header-title">{'CLOSE'}</span>
  //           </button>
  //           <button
  //             disabled={updating}
  //             id="save-entity"
  //             type="button"
  //             onClick={handleSave}
  //             className="common-btn"
  //             style={{
  //               width: 'fit-content',
  //               marginTop: 20,
  //               padding: 5,
  //             }}
  //           >
  //             <span className="header-title">{'SUBMIT'}</span>
  //           </button>

  //         </div>
  //       </div>
  //     </div>
  //   );
  // };
  const renderReviewSection = (
    remarks: string | undefined,
    status: string | undefined,
    updating: boolean,
    handleToggleEdit: () => void,
    handleStatusChange: (status: string) => void,
    handleRemarkChange: (remark: string) => void,
    handleSave: (status: string) => void,
    isAdmin: boolean,
    isReviewer: boolean
  ) => {
    const isReported = status === 'REPORTED';
    const isUnderReview = status === 'UNDER_REVIEW';
    const isReviewCompleted = status === 'REVIEW_COMPLETED';

    const adminButtonText = isReported ? 'SUBMIT FOR REVIEW' : 'SAVE';
    const reviewerButtonText = isReviewCompleted ? 'SAVE' : 'APPROVE';

    const adminButtonAction = 'UNDER_REVIEW';
    const reviewerButtonAction = 'REVIEW_COMPLETED';

    const handleButtonAction = () => {
      if (isAdmin) {
        handleSave(adminButtonAction);
      } else if (isReviewer) {
        handleSave(reviewerButtonAction);
      }
    };

    return (
      <div className="remark-container">
        <div className="remark-content">
          <Label for="adminRemark" className="label-font">
            Remark
          </Label>
          <textarea
            name="adminRemark"
            id="adminRemark"
            rows={5}
            value={remarks}
            onChange={e => handleRemarkChange(e.target.value)}
            // disabled={isAdmin && status === 'UNDER_REVIEW'}
          />
        </div>
        <div className="remark-content">
          <div className="button-container">
            {(isAdmin || isReviewer) && (
              <button
                disabled={updating || !remarks}
         
                type="button"
                onClick={handleButtonAction}
                className="common-btn"
                style={{
                  width: 'fit-content',
                  marginTop: 20,
                  padding: 5,
                }}
              >
                <span className="header-title">{isAdmin ? adminButtonText : reviewerButtonText}</span>
              </button>
            )}

            {isReviewer && isReviewCompleted && (
              <button
                disabled={updating || !remarks}
            
                type="button"
                onClick={() => handleSave('REPORTED')}
                className="common-btn"
                style={{
                  width: 'fit-content',
                  marginTop: 20,
                  padding: 5,
                }}
              >
                <span className="header-title">REJECT</span>
              </button>
            )}

            <button
              disabled={updating}
          
              type="button"
              onClick={handleToggleEdit}
              className="common-btn"
              style={{
                width: 'fit-content',
                marginTop: 20,
                padding: 5,
              }}
            >
              <span className="header-title">{'CLOSE'}</span>
            </button>
          </div>
        </div>
      </div>
    );
  };

  return incidentEntity && incidentEntity?.id ? (
    <div className="row">
      <Col md="12">
        <div className="detail-page-heading">
          <div className="detail-back-btn">
            <NavLink tag={Link} to={'/crowd-sourcing' + props.location.search}>
              <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
            </NavLink>
            <h2 style={{ color: 'rgb(114 108 108)' }}>Details</h2>
          </div>
        </div>

        <div className="row main-container">
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Id
              </p>
              <p className="detail-txt">{incidentEntity?.id}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Type
              </p>
              <p className="detail-txt">{incidentEntity?.incidentType}</p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Timeframe (Starts at)
              </p>
              <p className="detail-txt">{incidentEntity?.startTimeFrame}</p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Timeframe (Ends at)
              </p>
              <p className="detail-txt">{incidentEntity?.endTimeFrame}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Created By
              </p>
              <p className="detail-txt">{incidentEntity?.createdBy}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Incident Created Date
              </p>
              <p className="detail-txt">{incidentEntity?.createdDate.split('T')[0]}</p>
            </div>
          </div>

          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="statuses" className="detail-lable-txt">
                Status
              </p>
              <p className="detail-txt">{incidentEntity.status ? incidentEntity?.status : '--'}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id="videoUrl" className="detail-lable-txt">
                Video Title
              </p>
              <p className="detail-txt">{incidentEntity?.videoTitle}</p>
            </div>
          </div>
          <div className="col-md-4 bottom-border">
            <div className="d-flex">
              <p id=" videoId" className="detail-lable-txt">
                Video Id
              </p>
              <p className="detail-txt">{incidentEntity?.contentId}</p>
            </div>
          </div>
          <div className="col-md-6 bottom-border">
            <div className="d-flex">
              <p id=" videoId" className="detail-lable-txt">
                Video Url
              </p>
              <p
                onClick={() => handleUrlClick(incidentEntity.videoUrl)}
                style={{ color: 'blue', textDecoration: 'underline' }}
                className="detail-txt"
              >
                {incidentEntity?.videoUrl}
              </p>
            </div>
          </div>
        </div>
      </Col>
      <Col style={{ marginTop: '30px' }} md="12">
        <div className="detail-back-btn">
          <h2 style={{ marginLeft: '15px', color: 'rgb(114 108 108)' }}>Incident Description</h2>
        </div>

        <div className="row main-container">
          <p className="detail-txt">{incidentEntity?.incidentWord}</p>
        </div>
      </Col>
      <Col style={{ marginTop: '30px' }} md="12">
        <div className="detail-back-btn">
          <h2 style={{ marginLeft: '15px', color: 'rgb(114 108 108)' }}>Admin's Remark</h2>
        </div>

        {incidentEntity?.adminRemarks ? (
          editAdminsRemark ? (
            renderReviewSection(
              reviewState.adminRemark,
              reviewState.status,
              updating,
              handleToggleEditAdminRemark,
              handleStatus,
              handleAdminsRemark,
              handleSaveAdminsRemark,
              isAdmin,
              isReviewer
            )
          ) : (
            <div className="remark-container">
              <div className="remark-content">
                <div className="remark-text-container">
                  <p className="detail-txt">{incidentEntity?.adminRemarks}</p>
                </div>
              </div>
              <div className="remark-content">
                {isAdmin && incidentEntity.status !== 'REVIEW_COMPLETED' && (
                  <div className="button-container">
                    <button className="common-btn edit-btn" disabled={updating} type="button" onClick={handleToggleEditAdminRemark}>
                      <span className="header-title">EDIT</span>
                    </button>
                    <button className="common-btn delete-btn" disabled={updating} type="button" onClick={handleDeleteAdminRemark}>
                      <span className="header-title">DELETE</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )
        ) : editAdminsRemark ? (
          renderReviewSection(
            reviewState.adminRemark,
            reviewState.status,
            updating,
            handleToggleEditAdminRemark,
            handleStatus,
            handleAdminsRemark,
            handleSaveAdminsRemark,
            isAdmin,
            isReviewer
          )
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}
            className="alert alert-warning"
          >
            <div style={{ height: '100%' }}>Admin has not reviewed the incident yet !</div>
            {isAdmin && (
              <button disabled={updating} id="save-entity" type="button" onClick={handleToggleEditAdminRemark}>
                REVIEW
              </button>
            )}
          </div>
        )}
      </Col>
      <Col style={{ marginTop: '30px' }} md="12">
        <div className="detail-back-btn">
          <h2 style={{ marginLeft: '15px', color: 'rgb(114 108 108)' }}>Reviewer's Remark</h2>
        </div>

        {incidentEntity?.reviewerRemarks ? (
          editReviewersRemark ? (
            renderReviewSection(
              reviewState.reviewerRemark,
              reviewState.status,
              updating,
              handleToggleEditReviewesRemark,
              handleStatus,
              handleReviewersRemark,
              handleSaveReviewersRemark,
              isAdmin,
              isReviewer
            )
          ) : (
            <div className="remark-container">
              <div className="remark-content">
                <div className="remark-text-container">
                  <p className="detail-txt">{incidentEntity?.reviewerRemarks}</p>
                </div>
              </div>

              <div className="remark-content">
                {isReviewer && (
                  <div className="button-container">
                    <button className="common-btn edit-btn" disabled={updating} type="button" onClick={handleToggleEditReviewesRemark}>
                      <span className="header-title">EDIT</span>
                    </button>
                    <button className="common-btn delete-btn" disabled={updating} type="button" onClick={handleDeleteReviewerRemark}>
                      <span className="header-title">DELETE</span>
                    </button>
                  </div>
                )}
              </div>
            </div>
          )
        ) : editReviewersRemark ? (
          renderReviewSection(
            reviewState.reviewerRemark,
            reviewState.status,
            updating,
            handleToggleEditReviewesRemark,
            handleStatus,
            handleReviewersRemark,
            handleSaveReviewersRemark,
            isAdmin,
            isReviewer
          )
        ) : (
          <div
            style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', flexDirection: 'row' }}
            className="alert alert-warning"
          >
            <div style={{ height: '100%' }}>Reviewer has not reviewed the incident yet !</div>
            {isReviewer && incidentEntity.adminRemarks && (
              <button disabled={updating} id="save-entity" type="button" onClick={handleToggleEditReviewesRemark}>
                REVIEW
              </button>
            )}
          </div>
        )}
      </Col>
      <Col style={{ marginTop: '30px' }} md="12">
        <button
          disabled={updating}
          id="save-entity"
          type="button"
          onClick={incidentEntity.reportedBy.incidentComments ? handleUnBlockingUser : handleBlockingUser}
          className="common-btn"
          style={{
            width: 'fit-content',
            marginTop: 20,
            padding: 5,
          }}
        >
          <span className="header-title">
            {incidentEntity.reportedBy.incidentComments ? 'Block User From Commenting' : 'Unblock User '}
          </span>
        </button>
      </Col>
    </div>
  ) : null;
};

const mapStateToProps = ({ crowdSourcingState, authentication }: IRootState) => ({
  incidentEntity: crowdSourcingState.entity,
  totalItems: crowdSourcingState.totalItems,
  loading: crowdSourcingState.loading,
  updating: crowdSourcingState.updating,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isReviewer: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.REVIEWER]),
});

const mapDispatchToProps = {
  getEntity,
  blockUser,
  unBlockUser,
  saveAdminReview,
  saveReviewerReview,
  deleteAdminReview,
  deleteReviewerReview,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CrowdSourcingDetails);
