/* eslint-disable no-console */
import React, { useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { Button, Row, NavLink, Col, Label } from 'reactstrap';
import CreatableSelect from 'react-select/creatable';

import { AvFeedback, AvForm, AvGroup, AvInput, AvField } from 'availity-reactstrap-validation';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, TextFormat } from 'react-jhipster';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRootState } from 'app/shared/reducers';

import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';
import { getEntities as getSubscriptionPlans } from 'app/entities/subscription-plan/subscription-plan.reducer';
import { IStatuses } from 'app/shared/model/statuses.model';
import { getEntities as getStatuses } from 'app/entities/statuses/statuses.reducer';
import { getEntity, updateEntity, createEntity, reset ,uploadTrack ,deleteTrack} from './video-meta-data.reducer';
import { IVideoMetaData } from 'app/shared/model/video-meta-data.model';
import { convertDateTimeFromServer, convertDateTimeToServer, displayDefaultDateTime } from 'app/shared/util/date-utils';
import { mapIdList } from 'app/shared/util/entity-utils';
import { APP_LOCAL_DATETIME_DAY, APP_LOCAL_DATE_FORMAT, APP_LOCAL_DATETIME_FORMAT_Z } from 'app/config/constants';
import FormUpload from './video-meta-data-track-upload';
import { faUpload } from '@fortawesome/free-solid-svg-icons';
import { toast } from 'react-toastify';
import { Modal, ModalHeader, ModalBody, ModalFooter, } from 'reactstrap';



export interface IVideoMetaDataUpdateProps extends StateProps, DispatchProps, RouteComponentProps<{ id: string }> {
  uploadTrack(trackId: any, file: any): unknown;
}

export const VideoMetaDataUpdateTrack = (props: IVideoMetaDataUpdateProps) => {

  const audioRef = useRef(null);

  const handlePlay = () => {
    if (audioRef.current) {
      audioRef.current.currentTime = 1; 
    }
  };

  const [subscriptionPlanId, setSubscriptionPlanId] = useState('0');
  const [statusesId, setStatusesId] = useState('0');
  const [isNew, setIsNew] = useState(!props.match.params || !props.match.params.id);
  const [keyword, setKeyword] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [description, setDescription] = useState('');
  const [isFileError, setFileError] = useState<boolean>(false);
  const [fileShow, setFileShow] = useState(null);
  const [isPreview, setPreview] = useState(false);
  const [file, setFile] = useState<any>(null);





  const { videoMetaDataEntity, subscriptionPlans, statuses, loading, updating, loadingUpload } = props;

  const handleChangeDescription = event => {
    setDescription(event.target.value);
  };

  const uploadSingleFile = async e => {
    if (e?.target?.files[0]) {
      const file = e.target.files[0];
      try {
        setPreview(true);
        setFile(file);
        convertBase64(file);
      } catch (error) {
        console.error(error);
      }
    }
  };

  useEffect(() => {
    setKeyword([]);
    setTimeout(() => {
      keywordFunList();
      keywordFun();
    }, 1000);

    const keywordFun = () => {
      if (videoMetaDataEntity.keywords) {
        const array = videoMetaDataEntity?.keywords?.split(',');
        const keys = keyword;
        array?.map((item, index) => {
          return keys.push({ value: index, label: item });
        });
        setKeyword(keys);
      } else {
        setKeyword([]);
      }
    };
  }, [videoMetaDataEntity, isNew]);

  const keywordFunList = () => {
    if (videoMetaDataEntity?.keywordList) {
      const keys = keywordList;
      videoMetaDataEntity?.keywordList?.map((item, index) => {
        return keys.push({ value: index, label: item });
      });
      setKeywordList(keys);
    } else {
      setKeywordList([]);
    }
  };

  const convertBase64 = files => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(files);

      fileReader.onload = () => {
        resolve(fileReader.result);
        setFileShow(fileReader.result);
        setFileError(false);
      };

      fileReader.onerror = error => {
        reject(error);
        setFileError(true);
      };
    });
  };

  const handleChange = (field, value) => {
    switch (field) {
      case 'options':
        setKeyword(value);
        break;
      default:
        break;
    }
  };

  const handleClose1 = () => {
    props.history.push('/video-meta-data' + props.location.search);
  };

  useEffect(() => {
    if (isNew) {
      props.reset();
    } else {
      props.getEntity(props.match.params.id);
    }

    props.getSubscriptionPlans();
    props.getStatuses();
  }, []);

  useEffect(() => {
    if (props.updateSuccess) {
      // handleClose1();
      props.getEntity(props.match.params.id);
    }
  }, [props.updateSuccess]);


  const handleFormSubmitFile = async (event, values) => {   

    if (!file) {
      toast.warn('Add a MP3 file!', {
        position: toast.POSITION.TOP_RIGHT,
      });
      return;
    }

    let result = await props?.uploadTrack(videoMetaDataEntity?.id, file); 
    if (result) {
      // setFile(null)
      // setPreview(false);
    }
  };

  const [deletePopShow, setDeletePopShow] = useState(false)


  const handleDeleteTrack = async () => {    
    let result = await props?.deleteTrack(videoMetaDataEntity?.id); 
    if (result) {
      setFile(null)
      setPreview(false);
      setDeletePopShow(false)
    }
  };


  const deleteBtn = () => {
    setDeletePopShow(true)
  }

  const handleClose = () => {
    setDeletePopShow(!deletePopShow)
  };

  const confirmDelete = async () => {
    handleDeleteTrack()
  };

  return (
    <div>
      <Row className="justify-content-center">
        <Col md="12">
          <div className="detail-page-heading">
            <div className="detail-back-btn">
              <NavLink tag={Link} 
              to={'/video-meta-data' + props.location.search}
              >
                <FontAwesomeIcon icon="arrow-left" style={{ height: 30, width: 40 }} />
              </NavLink>
              <h2 style={{ color: 'rgb(114 108 108)' }}>Edit Video Meta Data</h2>
            </div>
          </div>
        </Col>
      </Row>
      <div className="row main-container">
        <Col md="12">
          {loading ? (
            <p>Loading...</p>
          ) : (
            <AvForm model={isNew ? {} : videoMetaDataEntity} onSubmit={handleFormSubmitFile}>
              <div className="row">
                <div className="col-md-4">

                  <AvGroup className="input-box-width">
                    <Label id="videoUrlLabel" for="video-meta-data-videoUrl" className="label-font">
                      Video Url
                    </Label>
                    <AvField id="video-meta-data-videoUrl" type="text" name="videoUrl" className="input-font" disabled />
                  </AvGroup>

                  <AvGroup className="input-box-width">
                    <Label id="videoUrlTitle" for="video-meta-data-videoTitle" className="label-font">
                      Video Title
                    </Label>
                    <AvField id="video-meta-data-videoTitle" type="text" name="videoTitle" className="input-font" disabled/>
                  </AvGroup>

                </div>

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="videoId" for="video-meta-data-videoId" className="label-font">
                      VideoId
                    </Label>
                    <AvField id="video-meta-data-videoId" type="text" name="videoId" className="input-font" disabled />
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="videoRecordDate" for="video-meta-data-videoRecordDate" className="label-font">
                      Video Record Date
                    </Label>
                    <AvField id="video-meta-data-videoRecordDate" type="date" className="form-control input-font " name="videoRecordDate" disabled/>
                    {/* <TextFormat type="date" id="video-meta-data-videoRecordDate"  className="form-control input-font " name="videoRecordDate" format={APP_LOCAL_DATETIME_DAY} /> */}
                  </AvGroup>
                </div>
                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="channelName" for="video-meta-data-channelName" className="label-font">
                      Channel Name
                    </Label>
                    <AvField id="video-meta-data-channelName" type="text" name="channelName" className="input-font" disabled/>
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="category" for="video-meta-data-category" className="label-font">
                      Category
                    </Label>
                    <AvField id="video-meta-data-category" type="text" name="category" className="input-font" disabled/>
                  </AvGroup>
                 
                </div>



         

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="speaker" for="video-meta-data-speaker" className="label-font">
                      Speaker
                    </Label>
                    <AvField id="video-meta-data-speaker" type="text" name="speaker" className="input-font" disabled/>
                  </AvGroup>
                  <AvGroup className="input-box-width">
                    <Label id="publishDateLabel" for="video-meta-data-publishDate" className="label-font">
                      Publish Date
                    </Label>
                    <AvField id="video-meta-data-publishDate" type="date" className="form-control input-font" name="publishDate" disabled />
                  </AvGroup>

                  <div className="row" style={{ marginBottom: '40px' }}>
                    <div className="col-md-12">
                      <div className="file-container">
                        <FormUpload
                          label={<FontAwesomeIcon icon={faUpload} style={{ marginRight: '5px' }} />}
                          errorMsg={isFileError}
                          inlineLabel={false}
                          value={null}
                          style={{ height: 150 }}
                          uploadSingleFile={uploadSingleFile}
                        />
                        {fileShow ? (
                          <FormUpload
                            isPreview={isPreview}
                            inlineLabel={false}
                            value={fileShow}
                            style={{ height: 50 }}
                            uploadSingleFile={uploadSingleFile}
                          />
                        ) : (
                          <>
                            {/* {entity && (props?.entity?.content?.[0]?.thumbnailUrl) && (
                              <audio controls src={props?.entity?.content?.[0]?.thumbnailUrl} style={{ height: 50 }} />
                            )} */}
                          </>
                        )}
                      </div>
                    </div>
                  </div>


                </div>

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="updatedDateLabel" for="video-meta-data-updatedDate" className="label-font">
                      Last Updated Date
                    </Label>
                    <AvField
                      id="video-meta-data-updatedDate"
                      // type="date"
                      className="form-control input-font"
                      name="updatedDate"
                      format={APP_LOCAL_DATE_FORMAT}
                      // value={updatedDate}
                      disabled
                    />
                    {/* <TextFormat className="form-control input-font " type="date"  name="updatedDate" value={updatedDate} format={APP_LOCAL_DATE_FORMAT} /> */}
                  </AvGroup>


                  <AvGroup className="input-box-width">
                    <Label id="audioTrack" htmlFor="audioTrack" className="label-font">Audio</Label>
                    <div className="audio-preview">

                      <audio controls preload="auto">
                        {videoMetaDataEntity?.audioUrl && (
                          <source src={videoMetaDataEntity.audioUrl} type="audio/mpeg"/>
                        )}
                        Your browser does not support the audio element.
                      </audio>
                    </div>
                  </AvGroup>


                </div>

                <div className="col-md-4">
                  <AvGroup className="input-box-width">
                    <Label id="serialNumberLabel" for="video-meta-data-serialNumber" className="label-font">
                      Serial Number
                    </Label>
                    <AvField id="video-meta-data-serialNumber" type="text" className="form-control input-font" name="serialNumber" disabled/>
                  </AvGroup>

                  <AvGroup className="input-box-width">
                  <Label id="audioUrl" for="audioUrl" className="label-font">
                    Audio URL
                  </Label>
                  <AvField
                    id="audioUrl"
                    type="text"
                    className="form-control input-font"
                    name="audioUrl"
                    disabled
                    title={videoMetaDataEntity.audioUrl} 
                    value={videoMetaDataEntity.audioUrl ? videoMetaDataEntity.audioUrl : '--'} 
                  />
                </AvGroup>



                </div>
                {/* <div className="col-md-4">
                 
                </div> */}
                {/* <Button tag={Link} id="cancel-save" to="/video-meta-data" replace color="info">
                <FontAwesomeIcon icon="arrow-left" />
                &nbsp;
                <span className="d-none d-md-inline">Back</span>
              </Button> */}
                {/* &nbsp; */}
                {/* {console.log('loadingUpload',loadingUpload)} */}


               


                <button
                  disabled={loadingUpload}
                  id="save-entity"
                  type="submit"
                  className="common-btn"
                  style={{ width: 200, marginTop: 20, marginRight: 20 }} // Added marginRight for spacing
                >
                  <span className="header-title">{loadingUpload ? 'Processing...' : 'Upload Audio'}</span>
                </button>

                {videoMetaDataEntity?.audioUrl && (
                <button
                  type="button" 
                  disabled={updating}
                  id="delete-entity"
                  className="common-btn"
                  onClick={() => deleteBtn()}
                  style={{
                    width: 200,
                    marginTop: 20,
                    border: '2px solid red', // Red border
                    color: 'red', // Red text color
                  }}
                >
                  <span className="header-title">{updating ? 'Processing...' : 'Delete Audio'}</span>
                </button>
              )}
              </div>
            </AvForm>
          )}
        </Col>
      </div>



      <Modal isOpen={deletePopShow} toggle={handleClose}>
        <ModalHeader toggle={handleClose}>Confirm delete operation</ModalHeader>
        <ModalBody id="satshrutApp.videoMetaData.delete.question">Are you sure you want to delete this Audio?</ModalBody>
        <ModalFooter>
          <Button color="secondary" onClick={handleClose}>
            <FontAwesomeIcon icon="ban" />
            &nbsp; Cancel
          </Button>
          <Button disabled={updating} id="jhi-confirm-delete-videoMetaData" color="danger" onClick={confirmDelete}>
            <FontAwesomeIcon icon="trash" />
            &nbsp; Delete
          </Button>
        </ModalFooter>
      </Modal>

    </div>
  );
};

const mapStateToProps = (storeState: IRootState) => ({
  subscriptionPlans: storeState.subscriptionPlan.entities,
  statuses: storeState.statuses.entities,
  videoMetaDataEntity: storeState.videoMetaData.entity,
  loading: storeState.videoMetaData.loading,
  updating: storeState.videoMetaData.updating,
  updateSuccess: storeState.videoMetaData.updateSuccess,
  loadingUpload: storeState.videoMetaData.loadingUpload,
});

const mapDispatchToProps = {
  getSubscriptionPlans,
  getStatuses,
  getEntity,
  updateEntity,
  createEntity,
  reset,
  uploadTrack,
  deleteTrack
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoMetaDataUpdateTrack);
