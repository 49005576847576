import './header.scss';

import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';

import { IRootState } from 'app/shared/reducers';
import { logout } from 'app/shared/reducers/authentication';
import { Navbar, Nav, NavLink, NavbarToggler, NavbarBrand, Collapse } from 'reactstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import { NavLink as Link, useLocation, useHistory } from 'react-router-dom';
import LoadingBar from 'react-redux-loading-bar';

import { Home, Brand, Search } from './header-components';
import { AdminMenu, EntitiesMenu, AccountMenu } from '../menus';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface ILoginModalProps {
  showModal: boolean;
  loginError: boolean;
  handleLogin: Function;
  handleClose: Function;
}

export interface IHeaderProps {
  isAuthenticated: boolean;
  isAdmin: boolean;
  isReviewer?:boolean;
  logout?: boolean;
  ribbonEnv: string;
  isInProduction: boolean;
  isSwaggerEnabled: boolean;
}

const Header = (props: IHeaderProps) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const history = useHistory();
  const location = useLocation();

  //destructuring pathname from location
  const { pathname } = location;

  //Javascript split method to get the name of the path in array
  const splitLocation = pathname.split('/');

  const renderDevRibbon = () =>
    props.isInProduction === false ? (
      <div className="ribbon dev">
        <a href="">Development</a>
      </div>
    ) : null;

  const toggleMenu = () => setMenuOpen(!menuOpen);

  /* jhipster-needle-add-element-to-menu - JHipster will add new menu items here */

  const logoutFun = () => {
    props.logout();
    history.push('/');
  };
  return (
    <div id="app-header">
      {/* {renderDevRibbon()} */}
      <LoadingBar className="loading-bar" />
      <Navbar dark expand="sm" fixed="top" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand headerIcon={splitLocation[1]} isAuthenticated={props.isAuthenticated} />
        <Collapse isOpen={menuOpen} navbar>
          {props.isAuthenticated && (
            <>
              {props.isAdmin ? (
                <>
                  {/* 
            <NavLink tag={Link}
              to="/live-video"
            >
              <span className={splitLocation[1] === "live-video" ? "active-header header-title" : "header-title"}>Live Video</span>
            </NavLink> */}
                  {/* {props.isAuthenticated && props.isAdmin && <AdminMenu showSwagger={props.isSwaggerEnabled} />} */}
                  <NavLink tag={Link} to="/video-meta-data">
                    <p className={splitLocation[1] === 'video-meta-data' ? 'active-header header-title' : 'header-title'}>
                      {' '}
                      Video Meta Data
                    </p>
                  </NavLink>
                  <NavLink tag={Link} to="/subscription-plan">
                    <p className={splitLocation[1] === 'subscription-plan' ? 'active-header header-title' : 'header-title'}>
                      Subscription Plan
                    </p>
                  </NavLink>

                  {/* <NavLink tag={Link} to="/video-view-history">
              <span className={splitLocation[1] === "video-view-history" ? "active-header header-title" : "header-title"}> Video View History</span>
            </NavLink> */}
                  {props.isAuthenticated && props.isAdmin && (
                    <NavLink tag={Link} to="/admin/user-management">
                      <p className={pathname === '/admin/user-management' ? 'active-header header-title' : 'header-title'}>
                        {' '}
                        User Management
                      </p>
                    </NavLink>
                  )}
                  {/* <NavLink tag={Link}
              to="/subscription-history"
            >
              <span className={splitLocation[1] === "subscription-history" ? "active-header header-title" : "header-title"}>Reports</span>
            </NavLink> */}
                  <NavLink tag={Link} to="/account/password">
                    <p className={pathname === '/account/password' ? 'active-header header-title' : 'header-title'}>Change Password</p>
                  </NavLink>
                  <NavLink tag={Link} to="/live-video">
                    <p className={pathname === '/live-video' ? 'active-header header-title' : 'header-title'}>Live Video</p>
                  </NavLink>
                  <NavLink tag={Link} to="/reports">
                    <p className={pathname === '/reports' ? 'active-header header-title' : 'header-title'}>Reports</p>
                  </NavLink>
                  <NavLink tag={Link} to="/admin-playlist">
                    <p className={pathname === '/admin-playlist' ? 'active-header header-title' : 'header-title'}>Manage Playlist</p>
                  </NavLink>

                  <NavLink tag={Link} to="/admin-association-management">
                    <p className={pathname === '/admin-association-management' ? 'active-header header-title' : 'header-title'}>
                      Manage Associations
                    </p>
                  </NavLink>
                  <NavLink tag={Link} to="/crowd-sourcing">
                    <p className={pathname === '/crowd-sourcing' ? 'active-header header-title' : 'header-title'}>Crowd Sourcing</p>
                  </NavLink>
		  
		  <NavLink tag={Link} to="/sparsh">
                    <p className={pathname === '/sparsh' ? 'active-header header-title' : 'header-title'}>
                      {/* sparsh */}
                      Sparsh
                    </p>
                  </NavLink>
		  
		  
                </>
              ) : props.isReviewer ? (
                <NavLink tag={Link} to="/crowd-sourcing">
                  <p className={pathname === '/crowd-sourcing' ? 'active-header header-title' : 'header-title'}>Crowd Sourcing</p>
                </NavLink>
              ) : null}
            </>
          )}
          {
            props.isAuthenticated ? (
              <div className="sing-btn">
                <NavLink onClick={() => logoutFun()}>
                  <span className="header-title">Sign out</span>
                </NavLink>
              </div>
            ) : null
            // splitLocation[1] === "login" ? '' :
            //   <div className='sing-btn'>

            //     <NavLink tag={Link} to="/">
            //       <span className='header-title'> Sign in</span>
            //     </NavLink>
            //   </div>
          }
        </Collapse>

        {/* {props.isAuthenticated && props.isAdmin && <AdminMenu showSwagger={props.isSwaggerEnabled} />} */}
      </Navbar>

      {/* <Navbar dark expand="sm" fixed="top" className="jh-navbar">
        <NavbarToggler aria-label="Menu" onClick={toggleMenu} />
        <Brand />
        <Search />
        <Collapse isOpen={menuOpen} navbar>
          <Nav id="header-tabs" className="ml-auto" navbar>
            <Home />
            {props.isAuthenticated && <EntitiesMenu />}
           
           
           
           
            {props.isAuthenticated && props.isAdmin && <AdminMenu showSwagger={props.isSwaggerEnabled} />}
          
            <AccountMenu isAuthenticated={props.isAuthenticated} />
          </Nav>
        </Collapse>
      </Navbar> */}
    </div>
  );
};

// export default Header;
const mapStateToProps = (storeState: IRootState) => ({
  logoutUrl: storeState.authentication.logoutUrl,
  idToken: storeState.authentication.idToken,
  isAdmin: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.ADMIN]),
  isReviewer: hasAnyAuthority(storeState.authentication.account.authorities, [AUTHORITIES.REVIEWER]),
});

const mapDispatchToProps = { logout };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Header);
