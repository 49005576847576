import { IQuestion } from 'app/shared/model/live-video.model';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { io, Socket } from 'socket.io-client';

const SERVER = 'https://satshrut.v2.mindnerves.com';

interface SocketProps {
  onConnect?: () => void;
  onDisconnect?: () => void;
  message?: string;
  setReplyData?: React.Dispatch<React.SetStateAction<any>>;
  setMessage?: React.Dispatch<React.SetStateAction<string>>;
  youtubeLiveVideoId?: string;
  questionId?: number;
}

const SocketComponent: React.FC<SocketProps> = ({
  onConnect,
  onDisconnect,
  message,
  setReplyData,
  youtubeLiveVideoId,
  setMessage,
  questionId,
}) => {
  const user = useSelector((state: IRootState) => state.authentication);
  const socketRef = React.useRef<Socket | null>(null);

  useEffect(() => {
    // export const socket = io(SERVER);
    socketRef.current = io(SERVER, {
      transports: ['websocket'], // forces websocket only
    });

    socketRef.current.on('connect', () => {
      console.log('Socket connected');
      if (onConnect) {
        onConnect();
      }
    });

    socketRef.current.on('disconnect', () => {
      console.log('Socket disconnected');
      if (onDisconnect) {
        onDisconnect();
      }
    });

    return () => {
      if (socketRef.current) {
        socketRef.current.disconnect();
      }
    };
  }, []); // Empty dependency array ensures the effect runs only once

  const sendMessage = () => {
    console.log('called');
    if (socketRef.current && message) {
      console.log('called');
      const data = {
        question: message,
        user: {
          id: user.account.id,
        },
        globalchat: questionId ? true : false,
        youtubeLiveVideo: parseInt(youtubeLiveVideoId),
        questionid: questionId,
      };
      console.log(data);
      socketRef.current.emit('send_message', data, response => {
        console.log('Message sent:', response);
      });
    }
  };
  useEffect(() => {
    socketRef.current.on('get_message', (data: IQuestion) => {
      console.log(data);
      data.videoId === parseInt(youtubeLiveVideoId) && setReplyData(data);
    });
  }, [socketRef.current]);

  useEffect(() => {
    sendMessage();
    setMessage('');
  }, [message]);

  return null; // This component doesn't render anything
};

export default SocketComponent;
