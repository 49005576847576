import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';
import { IUser, defaultValue } from 'app/shared/model/user.model';
import { ISubscriptionPlan } from 'app/shared/model/subscription-plan.model';

export const ACTION_TYPES = {
  FETCH_SUBSCRIPTIONPLAN_LIST: 'subscriptionPlan/FETCH_SUBSCRIPTIONPLAN_LIST',
  FETCH_ROLES: 'userManagement/FETCH_ROLES',
  FETCH_USERS: 'userManagement/FETCH_USERS',
  FETCH_USER: 'userManagement/FETCH_USER',
  CREATE_USER: 'userManagement/CREATE_USER',
  UPDATE_USER: 'userManagement/UPDATE_USER',
  DELETE_USER: 'userManagement/DELETE_USER',
  RESET: 'userManagement/RESET',
  FETCH_SUBSCRIPTIONPLAN_LIST_RENEWAL: 'subscriptionPlan/FETCH_SUBSCRIPTIONPLAN_LIST_RENEWAL',

};

const initialState = {
  loading: false,
  errorMessage: null,
  users: [] as ReadonlyArray<IUser>,
  authorities: [] as any[],
  user: defaultValue,
  updating: false,
  updateSuccess: false,
  totalItems: 0,
  getSubscriptionDetails:null,
  subscriptionPlan: [] as ReadonlyArray<ISubscriptionPlan>,
};

export type UserManagementState = Readonly<typeof initialState>;

// Reducer
export default (state: UserManagementState = initialState, action): UserManagementState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ROLES):
      return {
        ...state,
      };
    case REQUEST(ACTION_TYPES.FETCH_USERS):
    case REQUEST(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };

    case REQUEST(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.CREATE_USER):
    case REQUEST(ACTION_TYPES.UPDATE_USER):
    case REQUEST(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_USERS):
    case FAILURE(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST):
    case FAILURE(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST_RENEWAL):
      return {
        ...state,
        loading: false,
        getSubscriptionDetails: []
      };


    case FAILURE(ACTION_TYPES.FETCH_USER):
    case FAILURE(ACTION_TYPES.FETCH_ROLES):
    case FAILURE(ACTION_TYPES.CREATE_USER):
    case FAILURE(ACTION_TYPES.UPDATE_USER):
    case FAILURE(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ROLES):
      return {
        ...state,
        authorities: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.FETCH_USERS):
      return {
        ...state,
        loading: false,
        users: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_USER):
      return {
        ...state,
        loading: false,
        user: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.CREATE_USER):

    case SUCCESS(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST):
      return {
        ...state,
        loading: false,
        subscriptionPlan: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST_RENEWAL):
      return {
        ...state,
        loading: false,
        getSubscriptionDetails: action.payload.data,
      };

    case SUCCESS(ACTION_TYPES.UPDATE_USER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.DELETE_USER):
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        user: defaultValue,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/users';
// Actions
export const getUsers: ICrudGetAllAction<IUser> = (page, size, sort, search) => {
  const requestUrl = `${apiUrl}${sort ? `?searchValue=${search}&page=${page}&size=${size}&sort=${sort}` : ''}`;
  return {
    type: ACTION_TYPES.FETCH_USERS,
    payload: axios.get<IUser>(requestUrl),
  };
};

export const getRoles = () => ({
  type: ACTION_TYPES.FETCH_ROLES,
  payload: axios.get(`${apiUrl}/authorities`),
});

export const getSubscriptionDetails = (id) => ({
  type: ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST_RENEWAL,
  payload: axios.get(`/api/subscription-histories/all/currentuser/${id}`),
});

export const getUser: ICrudGetAction<IUser> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_USER,
    payload: axios.get<IUser>(requestUrl),
  };
};

export const createUser: ICrudPutAction<IUser> = user => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.CREATE_USER,
    payload: axios.post(apiUrl, user),
  });
  // dispatch(getUsers());
  return result;
};

export const updateUser: ICrudPutAction<IUser> = user => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    payload: axios.put('api/admin/users', user),
    meta: {
      successMessage: 'User Details Updated',
      errorMessage: 'Something Went Wrong',
    },
  });
  // dispatch(getUsers());
  return result;
};


export const updateUsersRenewal: ICrudPutAction<IUser> = user => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_USER,
    // payload: axios.post(`api/v3/open/buy-subscription/${user?.id}/${user?.subscriptionHistory?.subscriptionPlan?.id}/${encodeURIComponent(user.remarks)}`),
    payload: axios.post(`api/v3/open/buy-subscription/${user?.id}/24/${encodeURIComponent(user.remarks)}`),
    meta: {
      successMessage: 'User Renewal Details Updated',
      errorMessage: 'Something Went Wrong',
    },
  });
  // dispatch(getUsers());
  return result;
};

export const deleteUser: ICrudDeleteAction<IUser> = id => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.DELETE_USER,
    payload: axios.delete(requestUrl),
  });
  dispatch(getUsers());
  return result;
};

export const getEntities: ICrudGetAllAction<ISubscriptionPlan> = (page, size, sort) => ({
  type: ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST,
  payload: axios.get<ISubscriptionPlan>(`api/subscription-plans?cacheBuster=${new Date().getTime()}`),
});

export const getSubscription: ICrudDeleteAction<ISubscriptionPlan> = id => async dispatch => {
  const result = await dispatch({
    type: ACTION_TYPES.FETCH_SUBSCRIPTIONPLAN_LIST,
    payload: axios.get<ISubscriptionPlan>(`api/subscription-plans?cacheBuster=${new Date().getTime()}`),
  });
  if (result.value.status == 200) {
    return result.value.data;
  }
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
