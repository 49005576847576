import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';
import AdminSparshManagementAlbums from './admin-sparsh-management';
import AdminSparshManagementTracks from './admin-sparsh-management-tracks';
import AdminSparshManagementLyrics from './admin-sparsh-management-lyrics';
import AdminSparshManagementTrackEdit from './admin-sparsh-management-track-edit';
import AdminSparshUploadThumbnail  from './admin-sparsh-management-upload-thumbnail';
import AdminSparshAddNewAlbum  from './admin-sparsh-management-new-album';
import AdminSparshUploadTrack  from './admin-sparsh-management-upload-track';
import AdminSparshTrackAdd  from './admin-sparsh-management-add-track';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/album/:pPage/:pSort/:pOrder/:id/details`} component={AdminSparshManagementTracks} />
      <ErrorBoundaryRoute exact path={`${match.url}/album/:pPage/:pSort/:pOrder/:id/details/edit/:trackId`} component={AdminSparshManagementLyrics}/>
      <ErrorBoundaryRoute exact path={`${match.url}/album/:pPage/:pSort/:pOrder/:id/details/editTrack/:trackId`} component={AdminSparshManagementTrackEdit}/>
      <ErrorBoundaryRoute exact path={`${match.url}/album/:pPage/:pSort/:pOrder/:id/details/uploadTrack/:trackId`} component={AdminSparshUploadTrack}/>
      <ErrorBoundaryRoute exact path={`${match.url}/album/:pPage/:pSort/:pOrder/:id/details/uploadTrackForAlbum/:albumId`} component={AdminSparshTrackAdd}/>
      <ErrorBoundaryRoute exact path={`${match.url}/album/:pPage/:pSort/:pOrder/:id/details/uploadThumbnail/:trackId`} component={AdminSparshUploadThumbnail}/>
      <ErrorBoundaryRoute exact path={`${match.url}/album/:pPage/:pSort/:pOrder/:id/newAlbum`} component={AdminSparshAddNewAlbum}/>
      <ErrorBoundaryRoute path={match.url} component={AdminSparshManagementAlbums} />
    </Switch>
  </>
);

export default Routes;
