import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps, useHistory } from 'react-router-dom';

import { IRootState } from 'app/shared/reducers';
import { login, otp, googlelogin } from 'app/shared/reducers/authentication';
import LoginModal from './login-modal';
import OtpModal from './otp-modal';
import { replace } from 'lodash';
import { hasAnyAuthority } from 'app/shared/auth/private-route';
import { AUTHORITIES } from 'app/config/constants';

export interface ILoginProps extends StateProps, DispatchProps, RouteComponentProps<{}> { }

export const Login = (props: ILoginProps) => {
  const [showModal, setShowModal] = useState(props.showModal);
  const [otpShow, setOtpShow] = useState(false)
  const [jwtToken, setJwtToken] = useState(null)
  const [remember,setRemember]=useState<boolean>(false)
  const history = useHistory();

  useEffect(() => {
    setShowModal(true);
  }, []);

  const navigate = () => {
    history.push('/crowd-sourcing')
    window.location.reload()
  }

  const showOtpPage = (token,rememberMe) => {
    setOtpShow(true)
    setJwtToken(token)
    setRemember(rememberMe)
  }


  const handleLogin = (username, password, rememberMe = false,) => props.login(username, password, rememberMe, showOtpPage);
  const handleOtp = (otp) => props.otp(otp, jwtToken, navigate,remember);
  const googleLogin = (email, firstName, lastName, createdBy, login, activated) => props.googlelogin(email, firstName, lastName, createdBy, login, activated);

  const handleClose = () => {
    setShowModal(false);
    props.history.push('/');
  };

  const { location, isAuthenticated } = props;
  const { from } = (location.state as any) || { from: { pathname: '/', search: location.search } };
  if (isAuthenticated) {
    return <Redirect to={from} />;
  }
  return !otpShow ? <LoginModal showModal={showModal} googleLogin={googleLogin} handleLogin={handleLogin} handleClose={handleClose} loginError={props.loginError} />
    : <OtpModal showModal={showModal} googleLogin={googleLogin} handleOtp={handleOtp} handleClose={handleClose} loginError={props.loginError} />;

};

const mapStateToProps = ({ authentication }: IRootState) => ({
  isAuthenticated: authentication.isAuthenticated,
  loginError: authentication.loginError,
  showModal: authentication.showModalLogin,
  isAdmin: hasAnyAuthority(authentication.account.authorities, [AUTHORITIES.ADMIN]),
});

const mapDispatchToProps = { login, otp, googlelogin };

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(Login);
