import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { APP_LOCAL_DATE_FORMAT } from 'app/config/constants';
import { IRootState } from 'app/shared/reducers';
import React, { useEffect } from 'react';
import { TextFormat } from 'react-jhipster';
import { connect } from 'react-redux';
import { NavLink as Link, RouteComponentProps } from 'react-router-dom';
import { NavLink, Table } from 'reactstrap';
import { getEntities } from './subscription-plan.reducer';
import './subscription-plan.scss';

export interface ISubscriptionPlanProps extends StateProps, DispatchProps, RouteComponentProps<{ url: string }> { }

export const SubscriptionPlan = (props: ISubscriptionPlanProps) => {
  useEffect(() => {
    props.getEntities();
  }, []);

  const { subscriptionPlanList, match, loading } = props;
  return (
    <>
      <div className="main-container">
        <h2 id="subscription-plan-heading" style={{ color: 'rgb(114 108 108)' }}>
          Subscription Plans
          {/* <Link to={`${match.url}/new`} className="btn btn-primary float-right jh-create-entity" id="jh-create-entity">
          <FontAwesomeIcon icon="plus" />
          &nbsp; Create new Subscription Plan
        </Link> */}
          {/* <div className='float-right common-btn'>
          <NavLink tag={Link}
            to={`${match.url}/new`}>
            <span className='header-title'> Create Subscription Plan</span>
          </NavLink>
        </div> */}
        </h2>
        <div className="table-responsive">
          {subscriptionPlanList && subscriptionPlanList.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  {/* <th>Index</th> */}
                  <th>Subscription Level</th>
                  <th>Subscription Name</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>Start Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionPlanList.map((subscriptionPlan, i) => (
                  <tr key={`entity-${i}`}>
                    {subscriptionPlan?.isPriceChange == true ? (
                      <>
                        {/* <td>{i+1}</td> */}
                        <td>{subscriptionPlan.subscriptionLevel}</td>
                        <td>{subscriptionPlan.subscriptionName}</td>
                        <td>{subscriptionPlan.price}</td>
                        <td>{subscriptionPlan?.statuses?.statusValue}</td>
                        {/* <td>{subscriptionPlan?.updatedDate ? subscriptionPlan?.updatedDate : '--'}</td> */}
                        <td className="date-font">
                          {subscriptionPlan?.updatedDate ? (
                            <TextFormat type="date" value={subscriptionPlan?.updatedDate} format={APP_LOCAL_DATE_FORMAT} />
                          ) : (
                            '--'
                          )}
                        </td>
                       
                        {/* {subscriptionPlan?.statuses?.statusValue === 'Active' ? ( */}
                        <td>
                          <div className="btn-group flex-btn-group-container">
                            <NavLink tag={Link} to={`${match.url}/${subscriptionPlan.id}`}>
                              <FontAwesomeIcon
                                icon="eye"
                                style={{
                                  height: '25px',
                                  width: '25px',
                                  color: '#3a9b72',
                                }}
                                data-toggle="tooltip"
                                title="View"
                              />
                            </NavLink>
                            <NavLink tag={Link} to={`${match.url}/${subscriptionPlan.id}/edit`}>
                              <FontAwesomeIcon
                                icon="pencil-alt"
                                style={{
                                  height: ' 23px',
                                  width: '18px',
                                  marginRight: 2,
                                  color: '#275cb0',
                                }}
                                data-toggle="tooltip"
                                title="Edit"
                              />
                            </NavLink>
                            {/* <NavLink tag={Link} to={`${match.url}/${subscriptionPlan.id}/delete`}>
                        <FontAwesomeIcon icon="trash" style={{
                          'height': ' 23px',
                          'width': '18px',
                          marginBottom: 2,
                          color: '#db261d'
                        }} data-toggle="tooltip" title="Delete" />
                      </NavLink> */}
                            {/* <Button tag={Link} to={`${match.url}/${subscriptionPlan.id}`} color="info" size="sm">
                        <FontAwesomeIcon icon="eye" /> <span className="d-none d-md-inline">View</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${subscriptionPlan.id}/edit`} color="primary" size="sm">
                        <FontAwesomeIcon icon="pencil-alt" /> <span className="d-none d-md-inline">Edit</span>
                      </Button>
                      <Button tag={Link} to={`${match.url}/${subscriptionPlan.id}/delete`} color="danger" size="sm">
                        <FontAwesomeIcon icon="trash" /> <span className="d-none d-md-inline">Delete</span>
                      </Button> */}
                          </div>
                        </td>
                      </>
                    ) : (
                      <>
                      
                      </>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">No Subscription Plans found</div>
          )}
        </div>
      </div>
      <div className="main-container" style={{marginTop:"20px"}}>
        <h2 id="subscription-plan-heading" style={{ color: 'rgb(114 108 108)',marginBottom:"20px" }}>
          Subscription Plans History
        </h2>
        <div className="table-responsive">
          {subscriptionPlanList && subscriptionPlanList?.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Subscription Level</th>
                  <th>Subscription Name</th>
                  <th>Price</th>
                  <th>Status</th>
                  <th>End Date</th>
                </tr>
              </thead>
              <tbody>
                {subscriptionPlanList.map((subscriptionPlan, i) => (
                  <tr key={`entity-${i}`}>
                    {subscriptionPlan?.isPriceChange == false ? (
                      <>
                        <td style={{ backgroundColor: '#E9ECEF' }}>{subscriptionPlan.subscriptionLevel}</td>
                        <td style={{ backgroundColor: '#E9ECEF' }}>{subscriptionPlan.subscriptionName}</td>
                        <td style={{ backgroundColor: '#E9ECEF' }}>{subscriptionPlan.price}</td>
                        <td style={{ backgroundColor: '#E9ECEF' }}>{subscriptionPlan?.statuses?.statusValue}</td>
                        <td className="date-font" style={{ backgroundColor: '#E9ECEF' }}>
                          {subscriptionPlan?.updatedDate ? (
                            <TextFormat type="date" value={subscriptionPlan?.updatedDate} format={APP_LOCAL_DATE_FORMAT} />
                          ) : (
                            '--'
                          )}
                        </td>
                        {/* <td style={{ backgroundColor: '#E9ECEF' }} /> */}
                      </>
                    ) : (
                      <></>
                    )}
                  </tr>
                ))}
              </tbody>
            </Table>
          ) : (
            !loading && <div className="alert alert-warning">No Subscription Plans found</div>
          )}
        </div>
      </div>
    </>
  );
};

const mapStateToProps = ({ subscriptionPlan }: IRootState) => ({
  subscriptionPlanList: subscriptionPlan.entities,
  loading: subscriptionPlan.loading,
});

const mapDispatchToProps = {
  getEntities,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SubscriptionPlan);
