import React from 'react';
import { Switch } from 'react-router-dom';

import ErrorBoundaryRoute from 'app/shared/error/error-boundary-route';

import VideoMetaData from './video-meta-data';
import VideoMetaDataDetail from './video-meta-data-detail';
import VideoMetaDataUpdate from './video-meta-data-update';
import VideoMetaDataUpdateTrack from './video-meta-data-update-track';
import VideoMetaDataDeleteDialog from './video-meta-data-delete-dialog';

const Routes = ({ match }) => (
  <>
    <Switch>
      <ErrorBoundaryRoute exact path={`${match.url}/new`} component={VideoMetaDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:id/edit`} component={VideoMetaDataUpdate} />
      <ErrorBoundaryRoute exact path={`${match.url}/:pSearch/:pPage/:pSort/:pOrder/:id`} component={VideoMetaDataDetail} />
      <ErrorBoundaryRoute exact path={`${match.url}/:pSearch/:pPage/:pSort/:pOrder/uploadTrack/:id`} component={VideoMetaDataUpdateTrack} />
      <ErrorBoundaryRoute path={match.url} component={VideoMetaData} />
    </Switch>
    <ErrorBoundaryRoute exact path={`${match.url}/:id/delete`} component={VideoMetaDataDeleteDialog} />
  </>
);

export default Routes;
