import axios from 'axios';
import { ICrudGetAction, ICrudGetAllAction, ICrudPutAction, ICrudDeleteAction } from 'react-jhipster';

import { cleanEntity } from 'app/shared/util/entity-utils';
import { REQUEST, SUCCESS, FAILURE } from 'app/shared/reducers/action-type.util';

import { ILiveVideo, IQuestion } from 'app/shared/model/live-video.model';
import { toast } from 'react-toastify';
import { ICrowdSourcing, defaultValue } from 'app/shared/model/crowd-sourcing.model';

export const ACTION_TYPES = {
  FETCH_INCIDENTS_LIST: 'crowdSourcing/FETCH_INCIDENTS_LIST',
  FETCH_INCIDENT_ENTITY: 'crowdSourcing/FETCH_INCIDENT_ENTITY',
  UPDATE_INCIDENT_STATUS: 'crowdSourcing/UPDATE_INCIDENT_STATUS',

  RESET: 'crowdSourcing/RESET',
};

const initialState = {
  loading: false,
  errorMessage: null,
  entities: [] as ReadonlyArray<ICrowdSourcing>,
  entity: defaultValue,
  totalItems: 0,
  updateSuccess: false,
  updating: false,
};

export type CrowdSourcingState = Readonly<typeof initialState>;

// Reducer

export default (state: CrowdSourcingState = initialState, action): CrowdSourcingState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_INCIDENTS_LIST):
    case REQUEST(ACTION_TYPES.FETCH_INCIDENT_ENTITY):
      return {
        ...state,
        errorMessage: null,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.UPDATE_INCIDENT_STATUS):
      return {
        ...state,
        errorMessage: null,
        loading: true,
        updating: true,
      };
    case FAILURE(ACTION_TYPES.FETCH_INCIDENTS_LIST):
    case FAILURE(ACTION_TYPES.FETCH_INCIDENT_ENTITY):
    case FAILURE(ACTION_TYPES.UPDATE_INCIDENT_STATUS):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload,
      };

    case SUCCESS(ACTION_TYPES.FETCH_INCIDENTS_LIST):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        totalItems: parseInt(action.payload.headers['x-total-count'], 10),
      };
    case SUCCESS(ACTION_TYPES.FETCH_INCIDENT_ENTITY):
      return {
        ...state,
        loading: false,
        entity: action.payload.data,
      };
    case SUCCESS(ACTION_TYPES.UPDATE_INCIDENT_STATUS):
      return {
        ...state,
        errorMessage: null,
        loading: false,
        updating: false,
        updateSuccess: true,
      };
    case ACTION_TYPES.RESET:
      return {
        ...initialState,
      };
    default:
      return state;
  }
};

const apiUrl = 'api/admin/crowdSource';

// Actions

export const getIncidentsList = (page, size, sort, order, searchValue) => {
  const url = `${apiUrl}?searchValue=${searchValue}&page=${page}&size=${size}&sort=${sort},${order}`;
  return {
    type: ACTION_TYPES.FETCH_INCIDENTS_LIST,
    payload: axios.get<ICrowdSourcing[]>(url),
  };
};
export const getIncidentsListForReviewer = (page, size, sort, order, searchValue) => {
  const url = `api/content/crowdSource?searchValue=${searchValue}&page=${page}&size=${size}&sort=${sort},${order}`;
  return {
    type: ACTION_TYPES.FETCH_INCIDENTS_LIST,
    payload: axios.get<ICrowdSourcing[]>(url),
  };
};
export const updateIncidentStatus = (params, page, sort, order, search, size) => async dispatch => {
  const requestUrl = `${apiUrl}/UpdateStatus`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.put(requestUrl, params),
  });
  // dispatch(getLiveVideo());

  if (result.value.status == 200) {
    toast.success('Upated successfully');
    dispatch(getIncidentsList(page, size, sort, order, search));
    return result.value.data;
  }
};
export const updateIncident = params => async dispatch => {
  const requestUrl = `${apiUrl}/update`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.put(requestUrl, params),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Updated!', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // dispatch(getEntities());
  return result.value.data;
};
export const saveIncident = params => async dispatch => {
  const requestUrl = `${apiUrl}/save`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.post(requestUrl, params),
  });
  if (result.value.status == 200) {
    toast.success('Successfully Created!', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }

  // dispatch(getEntities());
  return result.value.data;
};
export const blockUser = (userId, id) => async dispatch => {
  const requestUrl = `api/admin/activateDeactivateUser/${userId}/true`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.put(requestUrl),
  });

  if (result.value.status == 200) {
    toast.success('Upated successfully');
    dispatch(getEntity(id));
    return result.value.data;
  }
};
export const saveAdminReview = (param, id) => async dispatch => {
  const requestUrl = `api/admin/crowdSource/UpdateStatus`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.put(requestUrl, param),
  });

  if (result.value.status == 200) {
    toast.success('Upated successfully');
    dispatch(getEntity(id));
    return result.value.data;
  }
};
export const deleteAdminReview = id => async dispatch => {
  const requestUrl = `api/admin/crowdSource/delete/adminRemarks/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.delete(requestUrl),
  });

  if (result.value.status == 200) {
    toast.success('Upated successfully');
    dispatch(getEntity(id));
  } else {
    toast.error('Something went wrong');
    dispatch(getEntity(id));
  }
};
export const deleteReviewerReview = id => async dispatch => {
  const requestUrl = `api/content/crowdSource/delete/reviewerRemarks/${id}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.delete(requestUrl),
  });

  if (result.value.status == 200) {
    toast.success('Upated successfully');
    dispatch(getEntity(id));
  } else {
    toast.error('Something went wrong');
    dispatch(getEntity(id));
  }
};
export const saveReviewerReview = (params, id) => async dispatch => {
  const requestUrl = `api/content/crowdSource/reviewerUpdate`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.put(requestUrl, params),
  });

  if (result.value.status == 200) {
    toast.success('Upated successfully');
    dispatch(getEntity(id));
    return result.value.data;
  }
};
export const unBlockUser = (userId, incidentId) => async dispatch => {
  const requestUrl = `api/admin/activateDeactivateUser/${userId}/false`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.put(requestUrl),
  });

  if (result.value.status == 200) {
    toast.success('Upated successfully');
    dispatch(getEntity(incidentId));
    return result.value.data;
  }
};
export const deleteIncident = incidentId => async dispatch => {
  const requestUrl = `api/admin/delete/${incidentId}`;
  const result = await dispatch({
    type: ACTION_TYPES.UPDATE_INCIDENT_STATUS,
    payload: axios.delete(requestUrl),
  });
  // dispatch(getLiveVideo());

  if (result.value.status == 200) {
    toast.success('Successfully Deleted !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  } else {
    toast.error('Something went wrong !', {
      position: toast.POSITION.TOP_RIGHT,
    });
  }
  return result;
};
export const getEntity: ICrudGetAction<ICrowdSourcing> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: ACTION_TYPES.FETCH_INCIDENT_ENTITY,
    payload: axios.get<ICrowdSourcing>(requestUrl),
  };
};

export const reset = () => ({
  type: ACTION_TYPES.RESET,
});
